export const AccountLabel = { 
	"en": "My Account",
	"el": "Λογαριασμός",
	"de": "Konto",
	"es": "Cuenta",
	"se": "Konto",
	"tr": "Hesap"
}

export const LogoutLabel = { 
	"en": "Logout",
	"el": "Εξοδος",
	"de": "Ausloggen",
	"es": "Cerrar Sesión",
	"se": "Utloggning",
	"tr": "Çıkış"
}

export const AllLabel = { 
	"en": "All",
	"el": "ΟΛΑ",
	"de": "ALLE",
	"es": "Todos"
}

export const LiveGamesShortLabel = {
	"en": "Live",
	"el": "Live",
	"de": "Live",
	"es": "En Vivo"
}

export default {
	CrashGamesLabel: {
		en: 'Crash Games',
		es: 'Juegos de Choque',
	},
	OtherLabel: {
		en: 'Other',
		es: 'Otros',
	},
	VirtualSports: {
		"en": "Virtual Sports",
		"es": "Deportes Virtuales"
	},
	
	BingoLabel: {
		"en": "Bingo"
	},
	
	MenuLabel: {
		"en": "Menu",
		"es": "Menú"
	},
	
	GameShowsLabel: {
		"en": "Game Shows",
		"es": "Espectáculos de Juegos"
	},
	
	TablePokerLabel: {
		"en": "Table & Poker",
		"es": "Mesa & Poker"
	},
	
	FirstPersonLabel: {
		"en": "First Person",
		"es": "Primera Persona"
	},
	
	RouletteLabel: {
		"en": "Roulette",
		"es": "Ruleta"
	},
	
	AllLabel,
	
	TournamentsLabel: {
		"en": "Tournaments",
		"el": "Τουρνουα",
		"de": "Turniere",
		"es": "Torneos"
	},
	
	TopGamesLabel: {
		"en": "TOP",
		"es": "TOP",
		"el": "TOP",
		"de": "Top"
	},
	
	SlotGamesLabel: {
		"en": "SLOTS",
		"es": "SLOTS",
		"el": "SLOTS",
		"de": "Slots"
	},
	
	MainHomeLabel: {
		"en": "Home"
	},
	
	CasinoGamesLabel: {
		"en": "Casino",
		"el": "Kazino",
		"de": "Casino",
		"tr": "Casino",
		"es": "Casino"
	},
	
	LiveGamesLabel: {
		"en": "Live Casino",
		"el": "Live Kazino",
		"de": "Live Casino",
		"tr": "Canlı Casino",
		"es": "Casino en Vivo"
	},
	
	BettingLabel: {
		"en": "Betting",
		"el": "Στοιχημα",
		"de": "Sportwetten",
		"tr": "Sporlar",
		"es": "Deportes"
	},
	
	SportBettingLabel: {
		"en": "Sports",
		"el": "Στοιχημα",
		"de": "Sportwetten",
		"tr": "Sporlar",
		"es": "Deportes"
	},
	
	AccountLabel,
	LogoutLabel,
	
	HelpLabel: {
		"en": "Help",
		"el": "Βοήθεια",
		"de": "Hilfe",
		"es": "Ayuda",
		"se": "Hjälp",
		"tr": "Yardım"
	},
	
	HelpLabelMobile: {
		"en": "Help",
		"el": "Βοήθεια",
		"de": "Hilfe",
		"es": "Ayuda",
		"se": "Hjälp",
		"tr": "Yardım"
	},
	
	SoundLabel: {
		"en": "Sound",
		"el": "Ήχος",
		"de": "Ton",
		"tr": "Ses",
		"es": "Sonido"
	},
	
	CustomerSupport: {
		"en": "Customer support",
		"el": "ΕΞΥΠΗΡΕΤΗΣΗ ΠΕΛΑΤΩΝ",
		"de": "Kundendienst",
		"tr": "Müşteri hizmetleri",
		"es": "Servicio al cliente"
	},
	
	OffersLabel: {
		"en": "Promotions",
		"el": "Προσφορες",
		"de": "Aktionen",
		"tr": "Teklifler",
		"es": "Promociones"
	},
	
	ExclusiveCatLabel: {
		"en": "Exclusives",
		"es": "Exclusivos",
		"el": "Αποκλειστικα",
		"de": "Exklusiv"
	},
	
	BrandedCatLabel: {
		"en": "BRANDED",
		"es": "MARCAS",
		"el": "BRANDED",
		"de": "Markenspiele"
	},
	
	TableCatLabel: {
		"en": "TABLE",
		"es": "JUEGOS DE CARTAS",
		"el": "TABLE",
		"de": "Tisch"
	},
	
	MoneyWheelsLabel: {
		"en": "MONEY WHEELS",
		"es": "RULETA DE LA FORTUNA",
		"el": "MONEY WHEELS",
		"de": "Geldräder"
	},
	
	VirtualsLabel: {
		"en": "Virtuals",
		"es": "Virtuales",
		"el": "Virtuals",
		"de": "Virtuell"
	},
	
	InPlayLabel: {
		"en": "In-Play",
		"es": "En Vivo",
		"el": "In-Play",
		"de": "Im Spiel"
	},
}