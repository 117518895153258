import { gsap } from "gsap";

export default function(){
	/**
	 * Sets the data, and removes the previously cached value. 
	 * @param {String} key 
	 * @param {String} val 
	 */
	$.fn.setData = function(key, val){
		$(this).removeData(key).attr('data-' + key, val);
		return $(this);
	};
	
	(function(){
	
		var instances = new Map();
	
		function pull(duration, isUp){
			duration = (duration || 200) / 1000;
	
			var element = $(this).get(0);
	
			var tween = instances.get(element);
			tween && tween.kill();
	
			element.style.transformOrigin = 'top';
	
			var val = -10;
	
			var props = {
				val: isUp ? 0 : val,
				opacity: isUp ? 1 : 0,
			}
	
			tween = gsap.to(props, {
				duration: duration,
				val: isUp ? val : 0,
				opacity: isUp ? 0 : 1,
				onUpdate: function(){
					element.style.transform = "translateY(" + props.val + "px) translateZ(0)";
					element.style.display = props.val > val ? '' : 'none';
					element.style.opacity = props.opacity;
				},
			});
	
			instances.set(element, tween);
	
			return element;
		}
	
		$.fn.pullUp = function(duration){
			pull.call(this, duration, true);
		}
	
		$.fn.pullDown = function(duration){
			pull.call(this, duration, false);
		}
	
	}());
}