import model from "@js/model.js";
import { arrayFilter, arrayFirst } from "@helpers/utils.js"
import Enum from "@shared/enum.js";

export default {
	createFreeGamesUrlParams(gameID){
		if(!model.promotions || !model.promotions.playerPromotion) return;

		var promoFreeGames = this.getFreeGamePromo(gameID);
		if(!promoFreeGames) return;

		var urlParams = {
			promotionId: promoFreeGames.id,
			promotionType: Enum.PlayerPromotions.Types.FreeGames,
		};

		var gameDesc = promoFreeGames.gameDesc;
		if(gameDesc){
			$.each(gameDesc.split(';'), (__, descVal) => {
				if(!descVal) return true;
				var desc = descVal.split('=')
				urlParams[desc[0]] = desc[1];
			});
		}

		return urlParams;
	},
	anyActiveFreeGames(){
		if(!model.promotions || !model.promotions.playerPromotion) return false;
		return arrayFilter(model.promotions.playerPromotion, promo => {
			return promo.type == Enum.PlayerPromotions.Types.FreeGames && promo.activated;
		}).length > 0;
	},
	getFreeGamePromo(gameID){
		if(!model.promotions || !model.promotions.playerPromotion) return;
		return arrayFirst(model.promotions.playerPromotion, promo => {
			return promo.type == Enum.PlayerPromotions.Types.FreeGames && promo.gameInstanceId == gameID;
		});
	}
}