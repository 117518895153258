import model from "@js/model.js";
export default {
	data(){
		return {
			message: null,
			status: null,
			displayed: false,
		}
	},
	created() {
		model.on('NetworkError', (e) => {
			this.message = e.msg;
			this.status = e.status;
			this.displayed = true;
			clearTimeout(this.timeout);
			if (e.status == 'success') this.timeout = setTimeout(() => this.displayed = false, 3000);
			if (model.networkError)
				document.activeElement.blur();
		});
	},
};