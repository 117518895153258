import { toDashCase } from "@helpers/utils.js"

import model from "../model.js";

export default function(){
	/**
	 * This set of binding handlers allow the addition of an event listener to the model, which will 
	 * be disposed the moment the bound element is dynamically removed. 
	 * As an example, you might dynamically add a component to the site and you'd probably want to add 
	 * an event listener which relates to the component, later on you could decide that the component 
	 * should be dynamically removed, therefore to perserve memory, the associated event listener
	 * should also be removed. 
	 *
	 * Therefore, it is advised to never user "model.on/model.addEventListener" directly within 
	 * a component's ViewModel if and only if you're deciding to remove and / or re-add the component 
	 * later on, since the event listeners won't be automatically disposed. 
	 */
	var eventKeys = [
		'PostMessageReceived',
		'WindowResize',
		'ForceResize',
		'UserProfileLoaded', 
		'GoTo', 
		'UploadDocumentSuccess', 
		'SetLang', 
		'UpdateLimits', 
		'GetPlayerInfoLoaded', 
		'PaymentTransactionListLoaded',
		'ZendeskLoaded',
		'ListUpdated_leaderboard',
		'ListUpdated_tournaments',
		'GameDisplayed',
		'GameStarted',
		'GameEnded',
		'LogoutComplete',
		'LoginComplete',
		'KeepAliveResponse',
		'GameHistoryLoaded',
		'ListUpdated_topWin',
		'OneSignalSubUpdate',
		'OneSignalPermUpdate',
		'ModalPageRedir',
		'PromoInfoPageReady',
		'PreparePromoInfoPage',
		'GoToCategory', 
		'BannerAdded',
		'ToggleGameTournPin',
		'JackpotInfoComplete',
		'PromoListLoaded',
		'ServerResponseSuccess',
		'UpdateCreditMeter',
		'SetCMeterVal',
		'ShowPaymentReceipt',
		'ShowPaymentPendingPage',
		'ShowPaymentIFrame',
		'ShowReceiptLoadingPage',
		'HandleBonusMultiplierWheel',
	];
	
	for(var i = 0, j = eventKeys.length; i < j; i++){
		var eventKey = eventKeys[i];
		model.vue.app.directive(createDirectiveName(eventKey), createBH(eventKey));
	}
	
	function createDirectiveName(name){
		name = `on${toDashCase(name)}`;
		let newName = '';
		let fragments = name.split('_');
		fragments.forEach((frag, index) => {
			if(index === 0) newName += frag;
			else newName += '-' + frag;
		});
		return newName;
	}
	
	function createBH(eventKey){
		let map = new Map();
		return {
			beforeMount(element, binding){
				var callback = binding.value;
				if(typeof callback !== 'function') return;
	
				let item = map.get(element);
				if(!item) item = {};
				item[eventKey] = callback;
				map.set(element, item);
	
				model.on(eventKey, callback);
			},
			unmounted(element){
				let item = map.get(element);
				item[eventKey] && model.off(eventKey, item[eventKey]);
			}
		}
	}
}