import model from "@js/model.js";
import Enum from "@shared/enum.js";

export default {
    initThirdPartyJackpots(){
        model.microgamingJackpotGames = [];

		model.on('ListUpdated_microgamingJackpot', () => {
			if(Array.isArray(model.microgamingJackpotInfo))
				for(var i = 0, j = model.microgamingJackpotGames.length; i < j; i++)
					this.setupGamesGlobalThumbnailJackpot(model.microgamingJackpotGames[i]);
		});
    },
    setupGamesGlobalThumbnailJackpot(thumbnail){
		if(!model.microgamingJackpotGames.includes(thumbnail))
			model.microgamingJackpotGames.push(thumbnail);

		if(!Array.isArray(model.microgamingJackpotInfo) || !thumbnail.microgamingParams) return;

		var jackpotInstance = model.microgamingJackpotInfo.find(item => {
			return item.moduleId == thumbnail.microgamingParams.moduleID && item.gamePayId == Enum.MGS_JP_GAME_PAY_ID;
		});

		if(jackpotInstance){
			thumbnail.jackpotInfo.setInfo(jackpotInstance);
			thumbnail.jackpotInfo.currencyCode = jackpotInstance.currencyIsoCode;
		}

		thumbnail.hasJackpot.value = Boolean(jackpotInstance);
	},
};