<script>
    import vm from "@componentsjs/upper-notification-view-model.js";
    export default {
    	extends: vm,
    };
</script>

<template>
	<div
	    v-upper-notification="text"
	    :style="{
	        'top': $root.fixedTop.value + 'px'
	    }"
	id="upper-notification">
	    <div v-text="text" class="inner"></div>
	</div>
</template>