export const LBUnavailable = {
	"en": "Leaderboard is currently unavailable.",
	"el": "Ο πίνακας κατάταξης δεν είναι διαθέσιμος.",
	"de": "Die Rangliste ist derzeit nicht verfügbar.",
	"es": "La tabla de clasificación no está disponible actualmente."
}

export const OpenTourLB = {
	"en": "Open leaderboard",
	"el": "Πίνακας κατάταξης",
	"de": "Rangliste öffnen",
	"es": "Τabla de clasificación"
}

export const LeaderboardLabel = {
	"en": "Leaderboard",
	"el": "Κατάταξη",
	"de": "Rangliste",
	"es": "Tabla de clasificación"
}
