import { isObject, toPascalCase } from "@helpers/utils.js"

import model from "../model.js";

export default function(){
	model.vue.app.directive('async-load', {
		beforeMount(element, binding){
			var params = binding.value;
			if(!isObject(params)){
				params = {
					obsLoaded: params,
				}
			}
	
			var eraseElemUponObserved = params.eraseElemUponObserved;
			var obsLoaded = params.obsLoaded;
	
			if(typeof eraseElemUponObserved !== 'boolean') eraseElemUponObserved = false;
	
			if(!obsLoaded) return;
	
			if(typeof params.compName === 'string'){
				params.compName = toPascalCase(params.compName);
				model.on(params.compName + 'ComponentLoaded', (e) => {
					if(eraseElemUponObserved) $(element).remove();
					e.dispose();
				});
			}
	
			let observer = null;
	
			observer = new IntersectionObserver(entries => {
				entries.every(entry => {
					if(!entry.isIntersecting) return true;
					observer.disconnect();
					obsLoaded.value = true;
					setTimeout(() => model.dispatchEvent('UpdateParallax'), 0);
					return false;
				});
			}, params.props || {});
	
			setTimeout(() => {
				if(typeof params.elementSelector === 'string'){
					element = $(params.elementSelector).get(0);
					eraseElemUponObserved = true;
				}
	
				observer?.observe?.(element);
			}, 150);
		}
	});
}