<script>
import dynamicImportLoad from '@helpers/dynamic-import-load.js';
import model from '@js/model.js';

export default {
    props: {
        loader: [Function, Object],
        bindings: {
            type: Object,
            default: {},
        },
        name: String,
        checkForViewport: {
            type: Boolean,
            default: true,
        },
        globalLoader: {
            type: Boolean,
            default: false,
        },
        underCondition: {
            type: Function,
            default: null,
        },
        maxPageHeight: Boolean,
        customClasses: String,
    },
    data(){
        return {
            loaded: false,
            observer: null,
        };
    },
    unmounted(){
        this.observer?.disconnect();
    },
    mounted(){
        if(typeof this.underCondition === 'function'){
            if(!this.underCondition()){
                let unwatch = this.$watch(this.underCondition, value => {
                    if(!value) return;
                    this._onLoadComponent();
                    unwatch();
                });
                return;
            }
            this._onLoadComponent();
            return;
        }

        if(!this.checkForViewport){
            this._onLoadComponent();
            return;
        }

        this.observer = new IntersectionObserver(entries => {
            entries.every(entry => {
                if(entry.isIntersecting){
                    this.observer.disconnect();
                    this._onLoadComponent();
                    return false;
                }
                return true;
            });
        });

        this.observer.observe(this.$el);
    },
    methods: {
        _onLoadComponent(){
            if(this.model.vue.app.component(this.name)){
                this.loaded = true;
                return;
            }

            if(this.globalLoader){
                dynamicImportLoad(() => inner.call(this));
                return;
            }

            inner.call(this);

            function inner(){
                return this.model.redeemLangObj(this.loader)().then(comp => {
                    this.model.vue.app.component(this.name, comp.default);
                    this.loaded = true;
                    model.dispatchEvent('UpdateFixedTop');
                });
            }
        },
    }
}
</script>

<template>
    <template v-if="loaded">
        <template v-if="$slots?.default">
            <slot></slot>
        </template>
        <template v-else>
            <component :is="name" v-bind="bindings" :class="customClasses" />
        </template>
    </template>
    <template v-else>
        <div v-show="!underCondition" :class="[`preloader-container ${customClasses || ''}`]" :style="maxPageHeight && {'min-height': $root.maxScreenHeight}">
            <preload-icon></preload-icon>
        </div>
    </template>
</template>