import model from "@js/model.js";
export default {
	initAccount(){
		if(typeof model.preventLoginWithSelfExcl !== 'boolean') model.preventLoginWithSelfExcl = true;
		if(typeof model.limitsFormIncluded !== 'boolean') model.limitsFormIncluded = true;

		this.whenAppReady(() => {
			model.on('ServerResponseSuccess', async e => {
				var action = e.action;
				switch(action.actionType){
					case "UserDataManipulation":
					await this.handleUserDataManipulation(action);
					break;
				}
			});
		});
	},
	accountBusiness(){
		return this.getBusiness('__accountBusiness', () => import("../business/account-business.js"));
	},
	sendJumioValidateRequest(){
		this.accountBusiness().then(module => module.sendJumioValidateRequest.apply(this, arguments));
	},
	sendJumioUploadRequest(){
		this.accountBusiness().then(module => module.sendJumioUploadRequest.apply(this, arguments));
	},
	async handleUserDataManipulation(){
		return (await this.accountBusiness()).handleUserDataManipulation.apply(this, arguments);
	},
	async updateUserProfile(){
		(await this.accountBusiness()).updateUserProfile.apply(this, arguments);
	},
	async sendUserDataManipulation() {
		(await this.accountBusiness()).sendUserDataManipulation.apply(this, arguments);
	},
	removeLimit(id, callback){
		this.sendRequest("UserDataManipulation", {
			type: 'RemoveLimits',
			limit_id: id,
		}, callback);
	},
}