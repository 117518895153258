import controller from "@js/controller/controller.js";
import model from "../model.js";
import dynamicImportLoad from "@helpers/dynamic-import-load.js";
import { removeItemFromArray } from "@helpers/utils.js";
import { nextTick } from "vue";

let lobbyHelperBusiness = null;
let previousGridsByCat = {};

export default () => {
	var html = '';
	
	model.columnResAmount.forEach(item => {
		var cond = [];
		item.minWidth && cond.push(`(min-width: ${item.minWidth}px)`);
		item.maxWidth && cond.push(`(max-width: ${item.maxWidth}px)`);

		html += `
			@media ${cond.join(' and ')} {
				.glis-item, 
				.game-list-inner {
					grid-template-columns: repeat(${item.amount}, 1fr);
				}
			}
		`;
	});

	var style = document.createElement('style');
	style.innerHTML = html;
	document.body.appendChild(style);

	model.vue.app.directive('game-list', {
		unmounted(element){
			element?.onDisposeCbxs?.forEach(cbx => cbx());
		},
		beforeMount(element, binding){
			var gameList = binding.value;
			if(!gameList) return;
	
			element.onDisposeCbxs = [];
			setupObservedOnceGLs(element, gameList);
			setupInlineGLs(element, gameList);
			setupLobbyHelperOrder(element, gameList);
		},
	});
}

function setupLobbyHelperOrder(element, gameList){
	if(gameList.lobbyHelperOrderId === null) return;

	if(!lobbyHelperBusiness){
		dynamicImportLoad(() => import("@js/controller/business/other/lobby-helper-business.js"), false).then(module => {
			lobbyHelperBusiness = new module.default();
			inner();
		});
		return;
	}

	inner();

	function inner(){
		let observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if(!entry.isIntersecting) return;

				observer.disconnect();
				observeEntry();
			});
		});

		function observeEntry(){
			if(!model.observables.lobbyHelper.response){
				gameList.clearThumbnails();
				gameList.ready = false;
				controller.requestLobbyHelper().then(inner);
				return;
			}

			inner();

			function inner(){
				let item = model.observables.lobbyHelper.response.find(x => x.id == gameList.lobbyHelperOrderId);
				if(!item) return;
				
				let thumbnails = gameList.originalList.slice(0);
				let posThs = [];

				let prevGrid = previousGridsByCat[gameList.categoryID];
				if(prevGrid){
					prevGrid.th.removeGrid(prevGrid.grid);
					delete previousGridsByCat[gameList.categoryID];
				}

				let featuredGame = controller.getGameByID(lobbyHelperBusiness.handleGame(item.featured[0]));
				let thFeatured = controller.getThumbnail(featuredGame);
				if(thFeatured){
					let grid = thFeatured.setGrid(Object.assign({
						column: '1/3',
						row: '1/3',
						categoryID: gameList.categoryID,
					}, featuredGame._tmpGrid));
					previousGridsByCat[gameList.categoryID] = {grid, th: thFeatured};

					removeItemFromArray(thumbnails, thFeatured);
					posThs.push(thFeatured);
				}

				item.list.forEach(gameItem => {
					let th = controller.getThumbnail(controller.getGameByID(lobbyHelperBusiness.handleGame(gameItem)));
					if(!th) return;
					
					removeItemFromArray(thumbnails, th);
					posThs.push(th);
				});

				thumbnails = posThs.concat(thumbnails);

				gameList.clearThumbnails();
				gameList.ready = true;
				nextTick(() => gameList.setupThumbnails(thumbnails));
			}
		}
	
		nextTick(() => observer.observe(element));

		let resetCbx = model.on('LoginComplete', 'LogoutComplete', 'SetLang', () => {
			observer.disconnect();
			nextTick(() => observer.observe(element));
		});

		element.onDisposeCbxs.push(() => {
			model.off('LoginComplete', 'LogoutComplete', 'SetLang', resetCbx);
		});
	}
}

function setupObservedOnceGLs(element, gameList){
	if(!gameList.shouldBeObserved) return;

	var observer = new IntersectionObserver(entries => {
		entries.every(entry => {
			if(entry.isIntersecting && !gameList.observedOnce){
				gameList.observeGL();
				refreshIScroll(gameList);
				return false;
			}
			return true;
		});

		if(!gameList.observedOnce) return;

		observer.disconnect();
	});

	var t = setTimeout(() => observer.observe(element), 0);

	element.onDisposeCbxs.push(() => {
		clearTimeout(t);
		observer.disconnect();
	});
}

function setupInlineGLs(element, gameList){
	if(!gameList.inline) return;

	let beingObserved = false;

	let callback = function(){
		beingObserved && gameList.setupInlineLists();
	}

	let observer = null;

	observer = new IntersectionObserver(entries => {
		entries.forEach(entry => beingObserved = entry.isIntersecting);
		if(!beingObserved) return;
		
		callback();
		refreshIScroll(gameList);
	});

	observer.observe(element);

	model.on('WindowResize', callback);
	element.onDisposeCbxs.push(() => {
		observer?.unobserve?.(element);
		model.off('WindowResize', callback);
	});
}

function refreshIScroll(gameList){
	if(!Array.isArray(gameList.iScrollList)) return;
	gameList.iScrollList.forEach(item => item.instance?.refresh?.());
}