export const TermsCondLabel = {
	"en": "Terms & Conditions",
	"el": "Όροι και Προϋποθέσεις",
	"de": "Geschäftsbedingungen",
	"es": "Términos y Condiciones",
	"se": "Regler och villkor",
	"tr": "Şart ve Koşullar"
}

export const ShowMoreLabel = {
	"en": "Show more",
	"el": "Εμφάνιση περισσότερων",
	"de": "Mehr anzeigen",
	"tr": "Daha fazla göster",
	"es": "Mostrar más"
}

export const ShowLessLabel = {
	"en": "Show less",
	"el": "Εμφάνιση λιγότερων",
	"de": "Weniger anzeigen",
	"tr": "Daha az göster",
	"es": "Mostrar menos"
}

export const LoadMoreLabel = {
	"en": "Load More",
	"es": "Cargar Más"
}

export const LoadLessLabel = {
	"en": "Load Less",
	"es": "Cargar menos"
}

export const InviteNow = { 
	"en": "Invite Now",
	"es": "Invitar ahora"
}


export const InviteFriends = { 
	"en": "Invite Friends",
	"el": "Προσκάλεσε φίλους",
	"de": "Freunde einladen",
	"tr": "Arkadaşlarını davet et",
	"es": {
		"FOUR_SG": "Invita a un amigo",
		"FUND": "Invitar Amigos"
	}
}

export const CopyLinkLabel = { 
	"en": "Copy link",
	"el": "Αντιγραφή link",
	"de": "Link kopieren",
	"tr": "Bağlantıyı kopyala!",
	"es": "Copia el enlace"
}


export const RecentlyEnded = {
	"en": "RECENTLY ENDED",
	"es": "TERMINADO RECIENTEMENTE"
}

export const StartingSoon = {
	"en": "Starting Soon",
	"es": "PRÓXIMAMENTE"
}

export const PromoCD_Days = { 
	"FOUR_SG": {
		"en": "Days",
		"el": "Ημέρες",
		"de": "Tage",
		"es": "Días"
	},
	"FUND": {
		"en": "D"
	}
}

export const PromoCD_Hours = { 
	"FOUR_SG": {
		"en": "Hours",
		"el": "Ώρες",
		"de": "Stunden",
		"es": "Horas"
	},
	"FUND": {
		"en": "H"
	}
}

export const PromoCD_Mins = { 
	"FOUR_SG": {
		"en": "Minutes",
		"el": "Λεπτά",
		"de": "Minuten",
		"es": "Minutos"
	},
	"FUND": {
		"en": "Min"
	}
}

export const PromoCD_Secs = { 
	"FOUR_SG": {
		"en": "Seconds",
		"el": "Δευτ/πτα",
		"de": "Sekunden",
		"es": "Segundos"
	},
	"FUND": {
		"en": "Sec"
	}
}

export const OpenTermsSeparatePage = { 
	"en": "Click here to read the terms.",
	"el": "Κάνε κλικ εδώ για να δεις τους όρους.",
	"de": "Klicken Sie hier, um die Bedingungen zu lesen.",
	"es": "Haga clic aquí para leer los términos."
}

export const GeneralPromoTC = { 
	"FOUR_SG": {
		"en": "General Promotion Terms",
		"el": "Γενικοί Όροι Μπόνους",
		"de": "Allgemeine Aktions-AGB",
		"es": "Promoción general T&C"
	},
	"FUND": {
		"en": "Promotion Terms",
		"es": "Términos de Promociones"
	}
}

export const TourStartsInOffers = { 
	"en": "STARTING",
	"el": "ΞΕΚΙΝΑΕΙ",
	"de": "START",
	"es": "COMENZANDO"
}

export const TourEndsInOffers = { 
	"en": "ENDING",
	"el": "ΛΗΓΕΙ",
	"de": "ENDE",
	"es": "FINALIZANDO"
}

export const TourEndedOffers = { 
	"en": "ENDED",
	"el": "ΕΛΗΞΕ",
	"de": "BEENDET",
	"es": "TERMINADO"
}

export const TourStartsIn = { 
	"en": "Starts in:",
	"el": "Ξεκινάει στις:",
	"de": "Start in:",
	"es": "Empieza en:"
}

export const TourEndsIn = { 
	"en": "Ends in:",
	"el": "Τελειώνει στις:",
	"de": "Ende in:",
	"es": "Termina en:"
}

export const TournamentTC = {
	"en": "Tournament T&C",
	"el": "Όροι Τουρνουά",
	"de": "Turnier AGB",
	"es": "Torneo T&C"
}

export const CashBackTC = {
	"en": "Cash Back T&C",
	"el": "T&C Προσφοράς Beam Back",
	"de": "Cash Back AGB",
	"es": "Términos y Condiciones del Bono de Reembolso"
}

export const AboutPromotion = {
	"en": "About",
	"el": "Σχετικά",
	"de": "Über das Aktionsangebot",
	"es": "Acerca de la promoción"
}

export const PromoTC = {
	"en": "Promotion T&C",
	"el": "Όροι Προσφοράς",
	"de": "Aktions-AGB",
	"es": "Promoción T&C"
}

export const TourSchedule = {
	"en": "Tournaments Schedule",
	"el": "Πρόγραμμα Τουρνουά",
	"de": "Turnierplan",
	"es": "Horario del Torneo"
}

export const SinglePromoLabel = {
	"en": "Promotion",
	"el": "Προβολή",
	"de": "Aktion",
	"es": "Promoción",
	"se": "Kampanj",
	"tr": "Promosyon"
}

export const PromoShortLabel = {
	"en": "Promo",
	"el": "ΠΡΟΣΦΟΡΕΣ",
	"de": "Angebot",
	"es": "Promoción",
	"se": "KAMPANJ",
	"tr": "Promo"
}

export const PromoFullLabel = {
	"en": "Promotions",
	"el": "Προσφορές",
	"de": "Aktionen",
	"es": "Promociones",
	"se": "KAMPANJER",
	"tr": "Promosyonlar"
}

export const PromoFullLabelSect = {
	"en": "Promotions",
	"el": "Προσφορες",
	"de": "Aktionen",
	"tr": "Promosyonlar",
	"es": "Promociones"
}

export const PromotionsGuestTitle = {
	"en": "Info",
	"el": "Χώρος μελών",
	"de": "Infos",
	"es": "info",
	"se": "Information",
	"tr": "Bilgi"
}

export const PromotionsGuestText = {
	"en": "Our promotional offers are only available to registered customers. Please proceed with the registration procedure to view our competitive offers!",
	"el": "Οι προσφορές μας είναι διαθέσιμες μόνο σε εγγεγραμένους χρήστες. Συνδέσου για να δεις τις αποκλειστικές μας προσφορές. Εάν δεν είσαι ήδη μέλος, προχώρησε στη διαδικασία εγγραφής.",
	"de": "Unsere Aktionsangebote sind nur für registrierte Kunden verfügbar. Fahre bitte mit der Registrierung fort, um unsere attraktiven Angebote zu erhalten!",
	"es": "Nuestras ofertas promocionales solo están disponibles para clientes registrados. ¡Regístrate para ver nuestras increíbles ofertas!",
	"se": "Våra kampanjerbjudanden är endast tillgängliga för registrerade kunder. Vänligen fortsätt med registreringsproceduren för att se våra konkurrenskraftiga erbjudanden!",
	"tr": "Promosyon tekliflerimiz sadece kayıtlı müşterilerimize özeldir. Rekabetçi tekliflerimizi incelemek için lütfen kayıt prosedürüne devam edin!"
}

export const Promotion = {
	"en": "Promotion",
	"el": "Προσφορά",
	"de": "Angebot",
	"es": "Promoción",
	"se": "Kampanj",
	"tr": "Promosyon"
}

export const LearnMoreLabel = { 
	"en": "Learn More",
	"el": "Περισσότερα",
	"de": "Mehr erfahren",
	"tr": "Daha fazla bilgi edin",
	"es": "Saber Más"
}

export const ReadMoreLabel = {
	"en": "Read More",
	"es": "Leer Más"
}

export const CopyShareReferralLink = {
	"en": "COPY AND SHARE YOUR REFERRAL LINK WITH FRIENDS.",
	"es": "COPIA Y COMPARTE TU ENLACE DE REFERENCIA CON AMIGOS."
}

// export const SpinBoosterTC = {
// 	"en": "Spin Booster T&C",
// 	"el": "T&C Προσφοράς Spin Booster",
// 	"de": "Spin Booster AGB",
// 	"es": "Spin Booster T&C"
// }

// export const SpaceBusTC = {
// 	"en": "Space Bus T&C",
// 	"el": "T&C Προσφοράς Space Bus",
// 	"de": "Weltraumbus AGB",
// 	"es": "Términos y Condiciones de Space Bus"
// }

// export const StellarStreamTC = {
// 	"en": "Stellar Stream T&C",
// 	"el": "T&C Προσφοράς Stellar Stream",
// 	"de": "Stellar Stream AGB",
// 	"es": "Términos y Condiciones de Stellar Stream"
// }

// export const WelcomeBonusTC = {
// 	"en": "Welcome Bonus T&C",
// 	"el": "Bonus Καλωσορίσματος T&C",
// 	"de": "Willkommensbonus AGB",
// 	"es": "Términos y Condiciones del Bono de bienvenida"
// }

// export const PlaysonTournament = {
// 	"en": "Playson Tournament",
// 	"el": "Τουρνουά Playson",
// 	"de": "Playson Turnier",
// 	"es": "Torneo Playson"
// }