<script>
    import vm from "@componentsjs/network-error-view-model.js";
    export default {
    	extends: vm,
    };
</script>

<template>
	<div 
	    :class="{
	        'mobile': $root.isMobile,
	        'displayed': displayed,
	    }"
	    :data-status="status"
	id="network-err-prompt">
	    <div class="inner">
	        <span v-text="message" class="msg"></span>
	        <preload-icon />
	    </div>
	</div>
</template>