var oldIScroll = window.IScroll;

window.IScroll = function(el, options){
	oldIScroll.call(this, el, options);
	
	$(this.wrapper).on('touchmove', preventTMWrapper);

	var prev = 0;
	var curr = 0;

	this.on('scrollEnd', function(){
		if(this.hasHorizontalScroll){
			prev = this.x;
			curr = this.x;
		}
		else
		{
			prev = this.y;
			curr = this.y;
		}
	});

	this.on('scroll', function(){
		if(this.hasHorizontalScroll){
			curr = this.x;
		}
		else
		{
			curr = this.y;
		}
	});

	this.on('scrollStart', function(){
		if(this.hasHorizontalScroll){
			prev = this.x;
			curr = this.x;	
		}
		else{
			prev = this.y;
			curr = this.y;	
		}
	});

	function preventTMWrapper(e){
		if(prev !== curr)
			e.preventDefault();
	}
}

window.IScroll.prototype = oldIScroll.prototype;

for(var propKey in oldIScroll){
	var prop = oldIScroll[propKey];
	window.IScroll[propKey] = prop;
}