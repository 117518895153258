import { isObject, toggleFullscreen as utilsToggleFullscreen, trimValue } from "@helpers/utils.js"
import Enum from "@shared/enum.js";

import model from "../model.js";
import controller from "../controller/controller.js";

export const constructRouteHref = {
	unmounted(element){
		element.onDispose?.();
	},
	beforeMount(element, binding){
		if(!model.isUI('FUND')){
			element.setAttribute('href', '#');
			return;
		}

		let catID = binding.value;

		onSetLang();
		model.on('SetLang', onSetLang);
		element.onDispose = () => model.off('SetLang', onSetLang);

		function onSetLang(){
			let href = controller.formatMultipagePathFromId(catID);
			element.setAttribute('href', href);
		}
	},
}

export const setupFirstBtnAsPrimary = {
	mounted(element, binding){
		var $elem = $(element);
		var index = binding.value;
		if(model.isUI('FUND')){
			index === 0 ? $elem.addClass('btn-success') : $elem.addClass('btn-info');
		}
		else{
			index === 0 ? $elem.addClass('btn-info') : $elem.addClass('btn-success');
		}
	}
}

export const toggleFullscreen = {
	unmounted(element){
		element.onDispose?.();
	},
	beforeMount(element, binding){
		var params = binding.value;
		if(!isObject(params.activeObs) || typeof params.selector !== 'string') return;
		
		if(typeof params.initAfterEvent === "string"){
			model.on(params.initAfterEvent, (e) => {
				init();
				e.dispose();
			});
			element.onDispose = () => model.off(params.initAfterEvent);
		}
		else init();

		function init(){
			setTimeout(() => {
				var targetElem = $(params.selector).get(0);
				if(!targetElem) return;

				binding.instance.$watch(() => params.activeObs.value, value => {
					utilsToggleFullscreen(targetElem);
				});
			}, 0);
		}
	},
}

export const externalApi = {
	beforeMount(element, binding){
		var apiLabel = binding.value;

		var observer = new IntersectionObserver(function(entries){
			entries.forEach(entry => {
				controller[entry.isIntersecting ? 'observeElemIfAPIChange' : 'unobserveElemIfAPIChange'](apiLabel, element);
			});
		});

		observer.observe(element);
	}
}

export const date = function(element, binding){
	var params = binding.value;
	if(typeof params !== 'object'){
		params = {
			value: params,
		};
	}

	if(typeof params.to !== 'string')
		params.to = 'toLocaleString';

	var value = params.value;
	if(typeof value === 'string' || typeof value === 'number')
		$(element).text((new Date(value))[params.to](Enum.Date.STR_LOCALE));
}

export const imgParallax2 = {
	mounted(element){
		if(model.isMobile) return;

		let $element = $(element);

		let offsetY = $element.offset().top; 
		let scrollPosY = 0;
		let docHeight = 0;

		model.on('WindowResize', 'ForceResize', 'UpdateParallax', onResize)();

		$(document).on('scroll', onScroll);

		function onResize(){
			offsetY = $element.offset().top;
			docHeight = $(document).outerHeight(true) / 8;
			setTimeout(() => onScroll(), 0);
		}

		function onScroll(){
			scrollPosY = model.scrollLocked ? (model.scrollLockedTop * -1) : window.scrollY;
			element.style.transform = `translateY(${(scrollPosY / offsetY - 1) * docHeight}px)`;
		}

		let observer = new IntersectionObserver(entries => {
			entries.every(entry => {
				if(entry.isIntersecting){
					model.dispatchEvent('WindowResize');
					observer.disconnect();
					return false;
				}
				return true;
			});
		});

		observer.observe(element);
	}
}

export const imgParallax = {
	beforeMount(element, binding){
		if(model.isMobile) return;

		var props = binding.value;
		if(!isObject(props)){
			props = {};
		}

		props = $.extend({
			simple: false,
			division: 3,
		}, props);

		var simple = props.simple;

		$(document).on('scroll', onScroll);

		var posY
		,	division = props.division;

		var reverseDiv = (simple ? 1 : -1);

		function onScroll(){
			posY = model.scrollLocked ? (model.scrollLockedTop * -1) : (window.scrollY || window.pageYOffset);
			element.style.transform = 'translateY(' + (posY / division * reverseDiv) + 'px) translateZ(0)';
		}

		!simple && model.on('WindowResize', 'ForceResize', 'UpdateParallax', () => {
			var docHeight = $(document).outerHeight(true) + (model.scrollLocked ? Math.abs(posY) : 0);
			element.style.height = (docHeight + docHeight / division) + 'px';
		});
	},
}

export const preloadImg = (() => {
	let parallaxTimeout = null;
	
		function handlePreload(params, preloadParams, $hiddenElem){
			if($hiddenElem.length === 0) return;

			let callback = null;
			let element = $hiddenElem.get(0);
			if(params.lang && isObject(params.imgUrl)){
				let urlLangs = params.imgUrl;
				let prevImg = null;

				callback = () => {
					$hiddenElem.addClass('hidden');

					let imgSrc = model.redeemLangObj(urlLangs);

					if(isObject(imgSrc) && imgSrc.auth && imgSrc.guest){
						if(model.logged){
							if(model.deposits() > 1 && imgSrc.ftd) imgSrc = imgSrc.ftd;
							else imgSrc = imgSrc.auth;
						}
						else imgSrc = imgSrc.guest;
					}

					preloadParams.imgUrl = imgSrc;
					if(prevImg !== imgSrc) preloadParams.imgUrl && controller.preloadImg(preloadParams);
					else $hiddenElem.removeClass('hidden');

					prevImg = imgSrc;
				};

				let imgSrc = model.redeemLangObj(urlLangs);
				if(isObject(imgSrc) && imgSrc.auth && imgSrc.guest){
					model.on('SetLang', 'LoginComplete', 'LogoutComplete', callback);
				}
				else model.on('SetLang', callback);
				
				model.langKey && callback();

				element?.onDispose?.push?.(() => model.off('SetLang', callback));
			}
			else controller.preloadImg(preloadParams);

			clearTimeout(parallaxTimeout);
			parallaxTimeout = setTimeout(() => model.dispatchEvent('UpdateParallax'), 500);
		}

		function setupPreload(element, params, onFinal){
			if(!params) {
				onFinal?.();
				return;
			}

			params = extractUrlFromObject(params);

			if(!isObject(params)){
				params = {
					imgUrl: params,
				}
			}

			if(isObject(params.imgUrl)) params.imgUrl = extractUrlFromObject(params.imgUrl);

			function extractUrlFromObject(obj){
				if(!isObject(obj)) return obj;

				if(typeof obj.default === 'string'){
					if(obj.webp && model.webpSupported) return obj.webp;
					return obj.default;
				}
				
				let keys = Object.keys(obj);
				let webpKey = keys.find(x => x.includes('.webp'));
				let defaultKey = keys.find(x => !x.includes('.webp') && x.includes('.'));

				if(model.webpSupported && webpKey) return obj[webpKey];
				else if(defaultKey) return obj[defaultKey];

				return obj;
			}

			var $element = $(element);
			var $hiddenElem = typeof params.hidingSelector === 'string' ? $element.find(params.hidingSelector) : $element;
			$hiddenElem.addClass('hidden');

			var $targetElement = $element;
			if(params.globalSelector) $targetElement = $(params.globalSelector);
			else if(params.targetSelector) $targetElement = $targetElement.find(params.targetSelector);
			else{
				if(params.$element === null) $targetElement = null;
				else if(Boolean(params.$element)) $targetElement = params.$element;
			}

			var preloadParams = {
				$element: $targetElement,
				imgUrl: params.imgUrl,
				webp: params.webp,
			};
			
			if(typeof params.success === 'function'){
				preloadParams.success = src => {
					$hiddenElem.removeClass('hidden');
					params.success.call(element, src, model);
					onFinal?.();
				};
			}
			else
			{
				preloadParams.success = () => {
					$hiddenElem.removeClass('hidden');
					onFinal?.();
				};
			}

			if(typeof params.error === 'function' || typeof params.imgErrorUrl === 'string'){
				preloadParams.error = e => {
					if(typeof params.imgErrorUrl === 'string'){
						params = $.extend(true, {}, params);
						params.imgUrl = params.imgErrorUrl;
						delete params.imgErrorUrl;
						setupPreload(element, params);
						onFinal?.();
						return;
					}

					$hiddenElem.removeClass('hidden');
					params.error.call(element, e, model);
					onFinal?.();
				};
			}

			handlePreload(params, preloadParams, $hiddenElem);
		};

	return {
		unmounted(element){
			element?.onDispose?.forEach?.(dispose => dispose());
		},
		beforeMount(element, binding){
			let params = binding.value;

			element.onDispose = [];
			let prevImg = null;

			if(typeof params === 'function'){
				onResize();
				model.on('WindowResize', 'ForceResize', onResize);
				element.onDispose.push(() => model.off('WindowResize', 'ForceResize', onResize));
				return;
			}

			setupPreload(element, params);

			function onResize(){
				let newImg = params();
				if(newImg !== prevImg) setupPreload(element, newImg);
				prevImg = newImg;
			}
		},
		updated(element, binding){
			let params = binding.value;
			if(!isObject(params) || !params.expectingChange) return; //TODO REFACTORING => remove property expectingChange and see if all of the other preloadimg update calls work correctly
			
			if(JSON.stringify(binding.value) !== JSON.stringify(binding.oldValue)) setupPreload(element, params);
		},
	}
})();

export const classFromEnum = {
	beforeMount(element, binding){
		let params = binding.value;
		let value = params.value;
		let classValues = params.classValues;

		for(let item of classValues){
			var targetClass = item.value;
			if(value === item.key){
				element.classList.add(targetClass);
				break;
			}
		}
	}
}

export const disableTouchMove = {
	beforeMount(element){
		$(element).on('touchmove', function(e){
			e.preventDefault();
		});
	}
}

export const elemFade = {
	updated(element, binding){
		var bool = binding.value;
		if(bool)
			$(element).fadeIn();
		else
			$(element).hide();
	}
}

export const elemFadeV2 = {
	updated(element, binding){
		var params = binding.value;
		if(typeof params === 'boolean'){
			params = {
				bool: params,
			};
		}
		else{
			params = {
				bool: false,
			};
		}
		$(element)[params.bool ? 'fadeIn' : 'fadeOut'](params.duration || 200);
	}
}

export const trimOnBlur = {
	beforeMount(element, binding){
		var observable = binding.value;
		var $input = $(element);
		$input.on('blur', () => observable.value = trimValue(observable.value, true));
	}
}

export const fadeElementIn = (() => {
	function updated(element, binding){
		var val = binding.value;
		if(typeof val !== 'object'){
			val = {
				active: val
			}
		}

		if(val.active){
			$(element).fadeIn();
			val.callback && val.callback();
		}
		else{
			$(element).hide();
			val.callbackHidden && val.callbackHidden();
		}
	}

	return {
		beforeMount(element, binding){
			element.style.display = 'none';
			updated(element, binding);
		},
		updated,
	}
})();