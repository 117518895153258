import { gsap } from "gsap";
import model from "../model.js";

var MOVE_DURATION = .25;

export default {
	init(){
		model.vue.app.directive('upper-notification', {
			updated(element, binding, vNode){
				var $element = $(element);
				var text = binding.value;
				if(!text) return;
		
				var vm = binding.instance;
				
				var props = {
					transY: 0
				}
		
				gsap.to(props, {
					duration: MOVE_DURATION,
					transY: 105,
					onUpdate: function(){
						$element.css('transform', 'translateY(' + props.transY + '%)')
					},
					onComplete: function(){
						setTimeout(function(){
							gsap.to(props, {
								duration: MOVE_DURATION,
								transY: 0,
								onUpdate: function(){
									$element.css('transform', 'translateY(' + props.transY + '%)')
								},
								onComplete: function(){
									if(typeof vm.onComplete === 'function')
										vm.onComplete();
								}
							});
						}, 4000);
					}
				});
			},
		});
	}
};