import model from "@js/model.js";
import UpperNotificationDirective from "@directives/upper-notification-directive.js";

export default {
	data(){
		return {
			pool: [],
			displayed: false,
			text: null,
		}
	},
	methods: {
		onComplete() {
			this.displayed = false;
			this.text = '';
			if (this.pool.length > 0) {
				this._show(this.pool[0]);
				this.pool.shift();
			}
		},
		_show(text) {
			if (!this.displayed) {
				this.text = text;
				this.displayed = true;
			} else if (text != this.text && !this.pool.includes(text))
				this.pool.push(text);
		}
	},
	created() {
		UpperNotificationDirective.init();
		model.on('ShowUpperNotification', (e) => {
			this._show(e.text);
		});
	},
};