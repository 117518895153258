export const Retry = {
	"en": "Retry",
	"el": "Δοκίμασε ξανά",
	"de": "Wiederholen",
	"es": "Procesar de nuevo"
}

export const ReloadPageLabel = {
	"en": "Reload Page",
	"el": "Επαναφόρτωση Σελίδας",
	"de": "Seite neu laden",
	"tr": "Sayfayı Yeniden Yükle",
	"es": "Volver a cargar la página"
}

export const ClientMessageTitleSuccess = {
	"en": "Success",
	"el": "Επιτυχία",
	"de": "Erfolg",
	"tr": "Başarılı",
	"es": "Éxito"
}

export const ClientMessageTitleFailure = {
	"FOUR_SG": {
		"en": "Failure",
		"el": "Αποτυχία",
		"de": "Fehler",
		"tr": "Başarısız",
		"es": "Fallo"
	},
	"FUND": {
		"en": "ERROR",
		"es": "ERROR"
	}
}

export const WelcomeBonus = {
	"en": "Welcome Bonus",
	"es": "Bono de Bienvenida"
}

export const WelcomeBonusBr = {
	"en": "Welcome<br>Bonus",
	"es": "Bono de<br>Bienvenida"
}

export const TopLists = {
	"en": "Home",
	"es": "Inicio"
}

export const NewLabel = { 
	"en": "New",
	"de": "Neu",
	"el": "Νεα",
	"es": "Nuevos"
}

export const FiltersLabel = {
	"en": "Filters",
	"el": "Φίλτρα",
	"de": "Filter",
	"tr": "Filtreler",
	"es": "Filtros"
}

export const AttentionLabel = {
	"en": "Attention",
	"el": "Προσοχή",
	"de": "Achtung",
	"tr": "Dikkat",
	"es": "Atención"
}

export const Cancel = { 
	"en": "Cancel",
	"el": "Ακύρωση",
	"de": "Abbrechen",
	"es": "Cancelar",
	"se": "Avbryt",
	"tr": "İptal"
}

export const cancel = { 
	"en": "Cancel",
	"el": "Ακύρωση",
	"de": "Abbrechen",
	"es": "Cancelar",
	"se": "Avbryt",
	"tr": "İptal"
}

export const Close = { 
	"en": "Close",
	"el": "Κλείσε",
	"de": "Schließen",
	"es": "Cerrar",
	"se": "Close",
	"tr": "Close"
}

export const Send = { 
	"en": "Send",
	"el": "Στείλε",
	"de": "Senden",
	"es": "Enviar",
	"se": "Send",
	"tr": "Send"
}

export const Info = { 
	"en": "Info",
	"el": "Πληροφορίες",
	"de": "Infos",
	"es": "info",
	"se": "Information",
	"tr": "Info"
}

export const Continue = { 
	"en": "Continue", 
	"el": "Συνέχεια",
	"de": "Fortfahren",
	"es": "Continuar",
	"pe": "Continuar",
	"se": "Fortsätt",
	"tr": "Devam et"
}

export const English = { 
	"en": "English",
	"el": "Αγγλικά",
	"de": "Englisch",
	"es": "Inglés",
	"se": "English",
	"tr": "English"
}

export const Greek = { 
	"en": "Greek",
	"el": "Ελληνικά",
	"de": "Griechisch",
	"es": "Griego",
	"se": "Greek",
	"tr": "Greek"
}

export const GameNotAvailOnDevice = {
	"en": "The game cannot be accessed on this device.",
	"el": "Το παιχνίδι δεν είναι προσβάσιμο από αυτή την συσκευή.",
	"de": "Dieses Spiel kann nicht auf diesem Gerät aufgerufen werden.",
	"tr": "Oyuna bu cihazda erişilemiyor.",
	"es": "No se puede acceder al juego desde este dispositivo."
}

export const NetworkConnError = { 
	"en": "A network error occurred while sending the request, retrying in {seconds} second(s)...",
	"el": "Σφάλμα κατά την αποστολή του αιτήματος. Επανάληψη σε {seconds} δευτερόλεπτα...",
	"de": "Beim Senden deiner Anfrage ist ein Netzwerkfehler aufgetreten, erneuter Versuch in {seconds} Sekunde(n)...",
	"es": "Se produjo un error en la red al enviar la solicitud, repetir intento en {seconds} segundo (s) ...",
	"se": "Ett nätverksfel uppstod när begäran skickades, försöker igen om {seconds} sekund(er)...",
	"tr": "İstek gönderilirken bir ağ hatası oluştu, {seconds} saniye içinde tekrar deneniyor..."
}

export const NetworkConnResolved = { 
	"en": "Connection resolved!",
	"el": "Έχεις επανασυνδεθεί επιτυχώς!",
	"de": "Verbindung wiederhergestellt!",
	"es": "¡Conexión resuelta!",
	"se": "Anslutning löst!",
	"tr": "Bağlantı çözüldü!"
}

export const OK = { 
	"en": "OK",
	"el": "ΟΚ",
	"es": "OK"
}

export const PlayNowLabel = {
	"en": "Play Now",
	"el": "Παίξε Τώρα",
	"de": "Jetzt Spielen",
	"es": "Juega Ahora"
}

export const PlayLabel = {
	"en": "Play",
	"es": "Jugar"
}

export const PopularLabel = {
	"en": "Popular",
	"es": "Populares"
}

export const SeeMoreLabel = { 
	"en": "See More",
	"es": "Ver Más"
}



export const Submit = { 
	"en": "Submit",
	"el": "Υποβολή",
	"de": "Absenden",
	"es": "Enviar",
	"se": "Skicka",
	"tr": "Gönder"
}

export const ViewAll = {
	"en": "View All",
	"es": "Ver Todo"
}

export const Today = { 
	"en": "Today",
	"el": "Σήμερα",
	"de": "Heute",
	"es": "Hoy",
	"se": "Idag",
	"tr": "Bugün"
}

export const Yesterday = { 
	"en": "Yesterday",
	"el": "Εχθές",
	"de": "Gestern",
	"es": "Ayer",
	"se": "Igår",
	"tr": "Dün"
}

export const gameplayResumed_text = { 
	"en": "The game {0} wasn't finished properly so it has to be resumed!",
	"el": "Το παιχνίδι {0} δεν τελείωσε σωστά και πρέπει να επαναληφθεί!",
	"de": "Das Spiel {0} wurde nicht ordnungsgemäß beendet, daher muss es fortgesetzt werden!",
	"es": "¡El juego {0} no se terminó correctamente, por lo que debe reanudarse!"
}

export const gameplayResumed_title = { 
	"en": "Gameplay resumed!",
	"el": "Ολοκλήρωση παιχνιδιού!",
	"de": "Spiel wiederaufgenommen!",
	"es": "¡Juego reanudado!",
	"se": "Spelandet återupptas!",
	"tr": "Oyun devam ettirildi!"
}

export const slotGame_notEnded_text = { 
	"en": "Please finish your current game.",
	"el": "Ολοκλήρωσε το παιχνίδι σου.",
	"de": "Bitte beende dein aktuelles Spiel.",
	"es": "Por favor termina tu juego actual.",
	"se": "Vänligen avsluta ditt nuvarande spel.",
	"tr": "Lütfen mevcut oyunu bitirin."
}

export const warning = { 
	"en": "Warning!",
	"el": "Προειδοποίηση!",
	"de": "Warnung!",
	"es": "¡Advertencia!",
	"se": "Varning!",
	"tr": "Uyarı!"
}