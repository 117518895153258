import { isObject } from "@helpers/utils.js";
import Enum from "@shared/enum.js";
let isAndroid;
let isIOS;
let isWinPhone;
let isMobile;

export default {
	init(params){
		isAndroid = params.isAndroid;
		isIOS = params.isIOS;
		isWinPhone = params.isWinPhone;
		isMobile = params.isMobile;

		let self = params.ns;

		Object.assign(params.ns, {
			getGameByID(gameId, byExtGameIDIfNotExist){
				if(!gameId) return;

				if((gameId + '').includes('|')) {
					let gids = gameId.split('|');
					let targetGame = null;

					gids.every(gid => {
						let game = self.getGameByID(gid, byExtGameIDIfNotExist);
						targetGame = game;
						if(targetGame) return false;
						return true;
					});

					return targetGame;
				}

				var gameMap = self.getGameMap();
				if(!gameMap) return;
		
				if(typeof byExtGameIDIfNotExist !== 'boolean') byExtGameIDIfNotExist = true;
		
				var game = gameMap[self.unwrapGameID(gameId)];
				if(!game && byExtGameIDIfNotExist) {
					game = self.getGameByExtID(gameId);
					if(!game) game = self.getGameByLobbyID(gameId);
				}
		
				return game;
			},
			getGameByExtID(gameId, productID){
				var games = self.getGames();
		
				if(productID) games = games.filter(x => x.productID === productID);
		
				for(var i = 0, j = games.length; i < j; i++){
					var game = games[i];
					if(self.evaluateGameId(game.extGameId, gameId, game.productID))
						return game;
				}
			},
			getGameByLobbyID(lobbyId){
				return self.getGames().find(x => x.providerLobby === lobbyId);
			},
			unwrapGameID(gameId, removeTableId){
				if(typeof removeTableId !== 'boolean')
					removeTableId = false;
		
				if(typeof gameId === 'object' && gameId !== null){
					var innerId = gameId[isMobile ? 'mobile' : 'desktop'];
					if(isMobile && typeof innerId === 'object' && innerId !== null){
						innerId = self.unwrapMobileGameID(innerId);
						if(innerId)
							return innerId;
		
						//if all fails, force desktop game id
						if(gameId.desktop)
							return gameId.desktop;
					}
					return innerId;
				}
				return (removeTableId && (gameId+'').includes('#')) ? gameId.substr(0, gameId.indexOf('#')) : gameId;
			},
			unwrapMobileGameID(mobileGameId){
				if(isAndroid && mobileGameId.android)
					return mobileGameId.android;
				if(isIOS && mobileGameId.ios)
					return mobileGameId.ios;
				if(isWinPhone && mobileGameId.winPhone)
					return mobileGameId.winPhone;
				if(typeof mobileGameId === 'object')
					return null;
				return mobileGameId;
			},
			evaluateGameId(gameId, targetGameId, productID){
				targetGameId = self.unwrapGameID(targetGameId);
				if(!targetGameId) return false;
		
				if((productID == Enum.ProductIDs.PlayTechLive || productID == Enum.ProductIDs.EvolutionLive) && !(targetGameId+'').includes('#')){
					if(isObject(gameId)){
						gameId = Object.assign({}, gameId);
						gameId.mobile = gameId.mobile.substr(0, gameId.mobile.indexOf('#'));
						gameId.desktop = gameId.desktop.substr(0, gameId.desktop.indexOf('#'));
					}
					else gameId = gameId.substr(0, gameId.indexOf('#'));
				}
		
				if(isObject(gameId)) return gameId.mobile == targetGameId || gameId.desktop == targetGameId;
				return gameId == targetGameId;
			},
			getGames(){
				return self.model ? self.model.games : self.games;
			},
			getGameMap(){
				return self.model.gameMap;
			},
		});
	},
}