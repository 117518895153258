import model from "@js/model.js";
import Enum from "@shared/enum.js";

export default {
	initSportBetting(){
		this.__sbContainer = null;

		model.observables.hasSB = Boolean(model.sportBettingConfig)
		if(!model.observables.hasSB) return;

		model.sportBettingConfig.scriptUrl = model.sportBettingConfig.url || model.sportBettingConfig[model.isMobile ? 'mobile' : 'desktop'];
		model.observables.hasSB = typeof model.sportBettingConfig.scriptUrl === 'string';
		if(model.view != Enum.Views.MAIN || !model.observables.hasSB) return;

		this.sbOptions = $.extend({
			token: '',
		}, model.sportBettingConfig.options || {});

		model.biaScriptLoaded = false;

		model.on('SetLang', () => this.initOrDestroySB());

		model.on('LoginComplete', 'LogoutComplete', e => {
			if(e.eventName == 'LoginComplete' && model.uToken) this.sbOptions.token = model.uToken;
			else if(e.eventName == 'LogoutComplete') this.sbOptions.token = '';
			this.initOrDestroySB();
		});

		model.on('GoToCategory', e => {
			var ids = e.ids;

			var targetId = null;
			var targetProp = null;
			for(var i = Enum.MAX_CATEGORY_RECURSION; i >= 0; i--){
				var prop = 'level' + i;
				var id = ids[prop];
				if(id){
					targetId = id;
					targetProp = prop;
					break;
				}
			}

			targetId && targetProp && initSBCallback.call(this, targetProp, targetId);
		});

		model.on('PostMessageReceived', () => {
			if(model.isPage(Enum.Pages.SPORTBETTING)){
				model.dispatchEvent('UpdateParallax');
			}
		});
	},
	sportBettingBusiness(){
		return this.getBusiness('__sportBettingBusiness', () => import("../business/sport-betting-business.js"));
	},
	initOrDestroySB(){
		if(model.isPage(Enum.Pages.SPORTBETTING)) initSB.call(this);
		else this.destroySportBetting();
	
		var legitBiaPage = model.categories.level1.find(item => item.biaPage && (!item.siteId || model.siteId == item.siteId));
		model.observables.hasSB = Boolean(legitBiaPage);
	},
	destroySportBetting(){
		if(!this.SB) return;
		
		this.SB?.remove?.();
		this.SB = null;
	
		if(this.__sbContainer) $(this.__sbContainer).empty();
	},
}

function initSBCallback(levelKey, levelVal){
	var arr = model.categories[levelKey];

	for(var i = 0, j = arr.length; i < j; i++){
		var cat = arr[i];
		if(cat.id == levelVal){
			var biaPage = cat.biaPage;
			if(typeof biaPage !== 'string'){
				if(Array.isArray(cat.categories) && cat.categories.length && typeof cat.categories[0].biaPage === 'string'){
					biaPage = cat.categories[0].biaPage;
				}
				else break;
			}

			this.initOrDestroySB();
			break;
		}
	}
}

function initSB(){
	this.sportBettingBusiness().then(module => module.initSportBetting.apply(this, arguments));
}