import model from "@js/model.js";
import Enum from "@shared/enum.js";

var timeout;
var jpInfoReqActive = false;

export default  {
	initJackpot(){
		if(model.view !== Enum.Views.MAIN) return;

		model.on('LoginComplete', () => jpInfoReqActive = true);

		model.on('ServerResponseSuccess', e => {
			var $xml = e.$xml;
			switch(e.actionType){
				case "JackpotInfo": 
					this.handleJackpot($xml);
					break;
			}
		});

		model.on('JackpotInfoComplete', e => {
			model.on('SetLang', () => this.sendJackpotInfo());
			e.dispose();
		});

		model.on('JackpotInfoComplete', () => onSendJackpotInfoTimer.call(this));

		model.on('LogoutComplete', () => {
			clearTimeout(timeout);
			timeout = setTimeout(() => this.sendJackpotInfo(), 2000);
		});
	},
	jackpotBusiness(){
		return this.getBusiness('__jackpotBusiness', () => import("../business/jackpot-business.js"), false);
	},
	activateJPInfoReq(callback){
		/*
			Due to minor performance improvements, the 
			jackpotinfo request shouldn't be initiated 
			directly upon page load.
		*/
		jpInfoReqActive = true;
		this.sendJackpotInfo(callback);
	},
	sendJackpotInfo(callback){
		clearTimeout(timeout);
		if(!model.logged && jpInfoReqActive) this.sendRequest('JackpotInfo', null, callback, {global: false});
		else if(typeof callback === 'function') callback();
	},
	handleJackpot(){
		this.jackpotBusiness().then(module => module.handleJackpot.apply(this, arguments));
	},
}

function onSendJackpotInfoTimer(){
	clearTimeout(timeout);
	if(model.logged) return;
	timeout = setTimeout(() => this.sendJackpotInfo(), 20000);
}