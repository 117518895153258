import model from "@js/model.js";
import { getParameterByName, clearLocationSearch } from "@helpers/utils.js"
import Enum from "@shared/enum.js";

export default {
	initCurrencyConversion(){
		var section = model.currencyConversion;

		var queryParam = 'currencyConversion';

		if(!section || !section.active || !section.toCurrency || !section.url) return;

		section.toCurrency = section.toCurrency.toUpperCase();

		if(getParameterByName(queryParam) === section.toCurrency){
			window.sessionStorage.setItem(queryParam, section.toCurrency);
			clearLocationSearch(queryParam);
			setTimeout(() => this.goTo(Enum.UIContexts.LOGIN), 1000);
		}

		model.on('LoginComplete', () => {
			if(!model.logged || !model.userProfile) return;
    		if(model.userProfile.currency.toUpperCase() === section.toCurrency || window.sessionStorage.getItem(queryParam) !== section.toCurrency) return;
			this.currencyConversionBusiness().then(module => module.loadCurrencyConversionPopup.call(this));
		});
	},
	currencyConversionBusiness(){
		return this.getBusiness('__currencyConversionBusiness', () => import("../business/currency-conversion-business.js"));
	},
}