import model from "@js/model.js";
import { addDirPath, removeEmptyFromArr, isObject, firstIfUndefined, getParameterByName } from "@helpers/utils.js"
import { nextTick, reactive } from "vue"
import Enum from "@shared/enum.js";

var additionalConfig = {
	topWin: {
		preproccess: topWinPreproccess,
	},
	jackpotWin: {
		preproccess(data){
			for(var i = 0, j = data.length; i < j; i++){
				var item = data[i];
				item.NickName = String(item.NickName).substr(0, 3) + '***';
				item.dateFormatted = model.formatDateTodayYesterday(item.Date);
				item.winNumber = Number(item.WinMon.replace(/[^0-9]/g, ''));
				item.Currency = item.Currency || 'EUR';
				var config = model.jackpotConfig && model.jackpotConfig.items;
				if(config) item.nameLabel = config[item.Level];
			}
		},
	},
	netEntJackpot: {
		preproccess(data){
			model.netEntJackpotInfo = [];
			for(var i = 0, j = data.length; i < j; i++){
				var item = data[i];
				model.netEntJackpotInfo.push({
					jackpotName: item.jackpotName,
					amount: item.currentJackpotValue.amount,
					currency: item.currentJackpotValue.amountCurrencyISOCode,
				});
			}
		},
	},
	microgamingJackpot: {
		preproccess(data){
			if(Array.isArray(data)) model.microgamingJackpotInfo = data;
		},
	},
	leaderboard: {
		getAuthUrl(label){
			var api = getAPI.call(this, label);
			if(typeof api.path !== 'string')
				return api.path;

			if(model.logged && model.userId)
				return addDirPath(api.path, model.userId);
			return api.path;
		},
		preproccess(data){
			data.sort((item1, item2) => item1.Position - item2.Position);
			var nqItem = data.find(item => item.Position == -1);
			if(nqItem){
				data.splice(data.indexOf(nqItem), 1);
				data.push(nqItem);
			}
		},
		underCondition(){
			return this.anyLocalTourFromPromoList();
		},
	},
	tournaments: {
		preproccess(data){
			model.tourGames = {};
			model.tourGameGroupIds = {};
			model.tourIds = [];

			var arrProps = ['StartDate', 'EndDate', 'VisibleFrom', 'LeaderboardVisibleFrom', 'VisibleTo'];
			data.forEach(tour => {
				arrProps.forEach(key => tour[key] = (new Date(tour[key])).getTime());
				model.tourGames[tour.TournamentId] = this.excludeDeviceOpposedGameIds(removeEmptyFromArr((tour.GameIds || '').split(',')));
				model.tourGameGroupIds[tour.TournamentId] = removeEmptyFromArr((tour.GameGroupIds || '').split(','));
				model.tourIds.push(tour.TournamentId);
			});
		},
		underCondition(){
			return this.anyLocalTourFromPromoList();
		},
	},
};

var timeouts = {};
var listObservers = {};
var EXCLUDED_JP_KEYS = ['jackpotwin'];

export default  {
	initExternalApis(){
		model.jpExtApiKeys = [];

		if(model.view !== Enum.Views.MAIN)
			return;

		model.bigWinThreshold = model.bigWinThreshold || 1000;
		if(!model.externalAPIs)
			return;

		/*
			Since external APIs take up a particular amount of resources, it's best 
			practice to avoid them on page load, thus why we're flagging all of them 
			as not activated. 
			*/
		$.each(model.externalAPIs, label => {
			var api = getAPI.call(this, label);
			if(!api) return true;

			api.activated = false;
			api.shouldSendRequest = additionalConfig[label].shouldSendRequest;
		});
		
		var t;
		model.on('LoginComplete', 'LogoutComplete', 'SetLang', e => {
			clearTimeout(t);
			if(e.eventName === 'LogoutComplete' && e.isPageLoad) return;

			t = setTimeout(() => execAllActivatedAPIs.call(this), 2000);
		});

		for(var key in model.externalAPIs){
			var keyLower = key.toLowerCase();
			if(keyLower.includes('jackpot') && !EXCLUDED_JP_KEYS.includes(keyLower))
				model.jpExtApiKeys.push(key);
		}
	},
	getExtAPI(){
		return getAPI.apply(this, arguments);
	},
	executeAPI(label, ignoreShouldSendReq, ignoreObserved, callback, global){
		var api = getAPI.call(this, label);
		if(!api) return;

		this.whenAppReady(() => {
			/*
				An API is flagged as active only when it's manually executed, particularly when 
				a web component actually has to display its data. 
				*/
			api.activated = true;
			fetchAPIData.call(this, label, ignoreShouldSendReq, ignoreObserved, callback, global);
		});
	},
	unobserveElemIfAPIChange(label, element){
		var item = listObservers[label];
		if(!item) return;

		var ind = item.elements.indexOf(element);
		ind !== -1 && item.elements.splice(ind, 1);
		item.observer.unobserve(element);
	},
	observeElemIfAPIChange: (function(){
		function setupElem(label, element, delayInitialExecution){
			var item = listObservers[label];
			item.elements.push(element);
			item.observer.observe(element);

			if(delayInitialExecution) item.delayedResponses.push(element);

			nextTick(() => {
				let obsoleteElems = item.elements.filter(el => !el.parentNode);
				while(obsoleteElems.length > 0) this.unobserveElemIfAPIChange(label, obsoleteElems.pop());
			});
		};

		return function(label, element, delayInitialExecution){
			if(typeof delayInitialExecution !== 'boolean') delayInitialExecution = false;

			var api = getAPI.call(this, label);
			if(!api) return;
			
			if(listObservers[label]){
				setupElem.call(this, label, element, delayInitialExecution);
				return;
			}

			Object.defineProperty(api, 'observed', (() => {
				return {
					get: () => {
						var elems = listObservers[label].elements;
						return Boolean(elems.find(item => item.observed));
					},
				};
			})());

			var then = null;
			var observer = new IntersectionObserver(entries => {
				for(var i = 0, j = entries.length; i < j; i++){
					var entry = entries[i];
					var elem = entry.target;
					elem.observed = entry.isIntersecting;
					var now = Date.now();
					if(elem.observed && (then === null || (now - then >= 20 * 1000))){
						then = now;
						var delayedResps = listObservers[label].delayedResponses;
						if(delayedResps.includes(elem)){
							fetchAPIDataWithTimeout.call(this, label);
							delayedResps.splice(delayedResps.indexOf(elem), 1);
						}
						else
							this.executeAPI(label);
						break;
					}
				}
			});
			
			listObservers[label] = {
				elements: [],
				observer: observer,
				api: api,
				delayedResponses: [],
			};

			setupElem.call(this, label, element, delayInitialExecution);
		};
	}()),
	getTournGameIDs(id){
		var games = model.tourGames?.[id];
		if(games && games.length > 0) return games;

		games = [];
		var groupIDs = model.tourGameGroupIds && model.tourGameGroupIds[id];
		return this.getGamesByGroupIDs(groupIDs, true);
	},
	sendTopWinnersRequest(callback){
		(typeof model.urls.topWinners === 'string') && this.webServiceSend({
			url: model.urls.topWinners,
			success: data => {
				topWinPreproccess.call(this, data, true);
				callback(data);
			},
			type: 'GET',
			global: false,
			dataType: 'json',
			contentType: false,
			processData: false,
		});
	}
}

function topWinPreproccess(data, formatWinMon){
	for(var i = 0, j = data.length; i < j; i++){
		var item = data[i];
		var game = this.getGameByID(item.GameId);

		/*
			It is possible that, in very rare cases, we could end up 
			with games that aren't configured on the client side.
			*/
		if(!game){
			data.splice(i, 1);
			i--;
			j--;
			continue;
		}
		else
			item.GameId = game.gameId;

		item.imgError = reactive({value: false});
		item.imgPath = game.imgPath;
		item.imgPathError = game.imgPathError;
		item.dateFormatted = model.formatDateTodayYesterday(item.Date);
		item.NickName = String(item.NickName).substr(0, 3) + '***';

		item.winNumber = Number(item.Win.replace(/[^0-9]/g, ''));

		item.thumbnail = this.getThumbnail(game);
		item.gameName = game.name;
		item.Currency = item.Currency || 'EUR';

		if(formatWinMon){
			item.WinMon = model.formatAsCurrency({
				value: Number((item.WinMon + '').replace(/,/g, '')),
				isCredits: false,
				includeSymbol: false,
			});
		}
		
		item.bigWin = false;
	}
}

function execAllActivatedAPIs(){
	$.each(model.externalAPIs, label => fetchAPIData.call(this, label));
}

function fetchAPIData(label, ignoreShouldSendReq, ignoreObserved, callback, global){
	clearTimeout(timeouts[label]);
	executeExternalAPI.call(this, label, ignoreShouldSendReq, ignoreObserved, data => {
		model.dispatchEvent('ListUpdated_' + label, {
			data: data,
		});
		typeof callback === 'function' && callback();
		fetchAPIDataWithTimeout.call(this, label, false, false, null, null, false);
	}, null, global, callback);
}

function fetchAPIDataWithTimeout(){
	if(arguments.length == 0) throw "No args specified!";
	var label = arguments[0];
	timeouts[label] = setTimeout(() => fetchAPIData.apply(this, arguments), getAPI.call(this, label).serverUpdateDelay || 10000);
}

function executeExternalAPI(label, ignoreShouldSendReq, ignoreObserved, onSuccess, onError, global, callbackIfCondFalse){
	if(typeof ignoreShouldSendReq !== 'boolean') ignoreShouldSendReq = false;
	if(typeof ignoreObserved !== 'boolean') ignoreObserved = false;
	if(typeof global !== 'boolean') global = false;

	var api = getAPI.call(this, label);
	if(!api) return;

	var conf = additionalConfig[label];
	if(isObject(conf) && typeof conf.underCondition === 'function' && !conf.underCondition.call(this)){
		typeof callbackIfCondFalse === 'function' && callbackIfCondFalse();
		return;
	}

	if(!api.activated || (!ignoreObserved && !api.observed) || (!ignoreShouldSendReq && typeof api.shouldSendRequest === 'function' ? !api.shouldSendRequest.call(this) : false)){
		fetchAPIDataWithTimeout.call(this, label);
		return;
	}

	var apiUrlOrTestData = (isObject(conf) && typeof conf.getAuthUrl === 'function') ? conf.getAuthUrl.call(this, label) : api.path;

	/*
		If the path itself is an object, we're assuming 
		it has paths for different currencies. 
		*/
	if(!Array.isArray(apiUrlOrTestData) && typeof apiUrlOrTestData === 'object' && apiUrlOrTestData !== null)
		apiUrlOrTestData = firstIfUndefined(apiUrlOrTestData, model.getCurrency().code);

	if(!apiUrlOrTestData){
		typeof onError === 'function' && onError();
		return;
	}

	/*
		If the 'apiUrlOrTestData' is a path in String format, then the lobby 
		will request the target data from the specified API, otherwise it'll be
		assumed that the 'apiUrlOrTestData' is an array / object of test data.
	*/
	if(typeof apiUrlOrTestData === 'string'){
		this.webServiceSend({
			url: apiUrlOrTestData.replace('{currency}', model.getCurrency().code),
			success: data => onSuccessPre.call(this, data, label, onSuccess, false),
			error: onError,
			type: 'GET',
			global: global,
			dataType: 'json',
			contentType: false,
			processData: false,
		});
	}
	else{
		//timeout is used to simulate server response delay
		setTimeout(() => onSuccessPre.call(this, apiUrlOrTestData, label, onSuccess, true), getParameterByName('longAPIRespDelay') == 'true' ? 1000000 : 50);
		console.log('%c' + label + ': TEST DATA UPDATED', 'padding:5px; background-color:#000; color: #0f0;');
	}
}

function onSuccessPre(data, label, callback, isTest){
	if(typeof isTest !== 'boolean')
		isTest = false;

	if(isTest && data)
		data = JSON.parse(JSON.stringify(data));

	if(!data || (Array.isArray(data) ? (data.length ? data[0].Error : false) : false)){
		fetchAPIDataWithTimeout.call(this, label);
		return;
	}
	
	if(typeof window._testNewExtAPIData === 'object' && window._testNewExtAPIData !== null){
		var extraTestData = window._testNewExtAPIData[label];
		if(Array.isArray(extraTestData)){
			data = data.concat(extraTestData);
			getAPI.call(this, label).path = data;
			delete window._testNewExtAPIData[label];
		}
	}

	var _case = additionalConfig[label];
	if(_case && typeof _case.preproccess === 'function') _case.preproccess.call(this, data);

	getAPI.call(this, label).data = data;		
	if(typeof callback === 'function') callback(data);
}

function getAPI(label){
	if(isObject(model.externalAPIs)) return model.externalAPIs[label];
}