export const PlayForRealLabel = {
	"en": "Play For Real",
	"es": "Juega de verdad"
}

export const PlayDemoLabel = {
	"en": "Play Demo"
}

export const PlayFunLabel = { 
	"en": "Play for fun",
	"el": "Παίξε για διασκέδαση",
	"de": "Zum Spaß spielen",
	"es": "Juega por diversion",
	"se": "Provspela gratis",
	"tr": "Eğlence için oyna"
}

export const PlayMoneyLabel = { 
	"en": "Play for money",
	"el": "Παίξε για χρήματα",
	"de": "Um echtes Geld spielen",
	"es": "Jugar con dinero"
}