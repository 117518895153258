import model from "@js/model.js";
import { trimValue, getKeyByValue, isObject, getDateUTCParams, toArray, getParameterByName, clearLocationSearch } from "@helpers/utils.js"
import { reactive } from "vue"
import Enum from "@shared/enum.js";

import gameModelApis from "@shared/game-model-apis.js";
import GameThumbnailModel from "@modelsjs/game-thumbnail-model.js";
import dynamicImportLoad from "@helpers/dynamic-import-load.js";

import { PlayDemoLabel, PlayForRealLabel, PlayFunLabel, PlayMoneyLabel } from "@lang/gameHandling.js";

export default {
	initGameHandling(){
		if(typeof model.mobileGameRedirect !== 'boolean') model.mobileGameRedirect = true;

		model.pushLang({
			PlayDemoLabel, 
			PlayForRealLabel, 
			PlayFunLabel, 
			PlayMoneyLabel,
		});

		if(typeof model.preferredTopGamesDomain === 'undefined') model.preferredTopGamesDomain = null;

		if(!Array.isArray(model.activeSvgImgs) || model.isIE) model.activeSvgImgs = [];

		model.activeGames = {};

		gameModelApis.init({
			isAndroid: model.isAndroid,
			isIOS: model.isIOS,
			isWinPhone: model.isWinPhone,
			isMobile: model.isMobile,
			ns: this,
		});

		this.gameBusinesses = [];
		model.observables.gameBusinesses = [];

		model.gameThumbnailMap = {};
		model.arrGameThumbnails = [];
		model.thsHourly = {};
		model.langPosThs = [];

		this.gameBusinessInstances = {};

		model.on('GameStarted', 'LogoutComplete', () => {
			delete model.gameResumeId;
		});

		model.on('GameStarted', () => {
			model.dispatchEvent('HideAllMsgs', 'LockBodyScroll');
		});

		configThumbnailLoadScroll.call(this);

		model.observables.gameDisplayedObs = false;

		model.on('GameStarted', 'GameEnded', 'GameDisplayed', () => {
			var gameDisplayed = this.isAGameDisplayed();
			if(!gameDisplayed || !gameDisplayed.game) model.observables.gameDisplayedObs = false;
			else model.observables.gameDisplayedObs = gameDisplayed.game.loaded ? gameDisplayed.game.displayed : false;
		});

		let startgameid = getParameterByName('startgameid');
		if(startgameid){
			this.whenAppReady(() => {
				let evt = 'LogoutComplete'; 
				if(model.uToken) evt = 'LoginComplete';

				model.on(evt, e => {
					this.startGame(startgameid);
					e.dispose();
				});
			});
			clearLocationSearch('startgameid', 'iframeurl', 'promoId');
		}

		window.testLoadModule = async moduleName => {
			await this.gameStartBusiness();
			switch(moduleName){
				case 'egoe' : 
				await import("@gameproviders/egoe-game-business.js");
				break;
			}
		}
	},
	retrieveAllIDsFromGameID(objGameID){
		if(!isObject(objGameID)) return [objGameID];

		let res = [];
		retrieveIDs(objGameID);

		function retrieveIDs(baseId){
			Object.keys(baseId).forEach(key => {
				let id = baseId[key];
				if(isObject(id)){
					retrieveIDs(id);
					return;
				}
				res.push(id);
			});
		}

		return res;
	},
	async gameStartBusiness(){
		return this.getBusiness('__gameStartBusiness', () => import("../business/game-start-business.js"));
	},
	afterGamesFetched(callback){
		if(model.gamesFetched){
			callback();
			return;
		}

		model.on('GamesFetched', e => {
			e.dispose();
			callback();
		})
	},
	getTypeCasinoOrLive(game){
		let casinoAmount = 0;
		let liveAmount = 0;

		game.categories.forEach(catId => {
			let cat = this.getCatByCatID(catId);
			if(!cat) return;

			while(cat.parent) cat = cat.parent;
			cat.id === Enum.CATEGORY_IDS.CASINO ? casinoAmount++ : liveAmount++;
		});

		return casinoAmount > liveAmount ? 'casino' : 'live';
	},
	notifyGameResume: (() => {
		var msgDisplayed = {value: false};

		return function(checkIsRequired){
			return new Promise(resolve => {
				this.gameStartBusiness().then(module => {
					resolve(module.notifyGameResume.call(this, checkIsRequired, msgDisplayed));
				});
			});
		}
	})(),
	getGameByRoute(providerRoute, gameNameRoute){
		var games = model.games.filter(game => {
			return gameNameRoute == this.formatValueForPathname(game.name);
		});

		if(games.length > 1){
			var targetGame = games.find(game => {
				return providerRoute == this.formatValueForPathname(this.getProviderDN(this.getProviderConfig(game)));
			});
			if(!targetGame) return games[0];
			return targetGame;
		}

		if(games.length === 1) return games[0];
	},
	getProviderConfig(game){
		var mgsp = game.microgamingParams;
		var isBTG = mgsp && mgsp.supplier && trimValue((mgsp.supplier || '').toLowerCase()).includes('bigtimegaming');

		return model.providerConfig.find(item => {
			if(isBTG) return trimValue(item.id || '').toLowerCase().includes('bigtimegaming')
			return item.gameGroups.includes(game.gameGroup);
		});
	},
	getProviderDN(pConfig){
		if(pConfig) return pConfig.label;
		return getKeyByValue(Enum.ProductIDs, this.productID);
	},
	excludeDeviceOpposedGameIds(arr){
		for(var i = 0, j = arr.length; i < j; i++){
			var id = arr[i];
			var game = this.getGameByID(id);
			if(!game || this.unwrapGameID(game.gameId) != id){
				arr.splice(i, 1);
				i--;
				j--;
			}
		}
		return arr;
	},
	getCategoryTourGameThs(tournament, ignorePromoActive){
		var thumbnailLists = [];
		var promos = this.getCategoryTourPromo(tournament, ignorePromoActive);
		if(promos.length > 0){
			promos.forEach(promo => {
				let thumbnails = [];
				if(Array.isArray(promo.gameIDs)){
					promo.gameIDs.every(id => {
						var game = this.getGameByID(isObject(id) ? id.gameID : id);
						if(!game) return true;
						
						var th = this.getThumbnail(game);
						th && thumbnails.push(th);
	
						return true;
					});
				}
				else if(promo.gameIDsByGameGroup){
					let gameIDs = this.getGamesByGroupIDs(promo.gameIDsByGameGroup, true);
					gameIDs.every(gameId => {
						var game = this.getGameByID(gameId);
						if(!game) return true;

						if(Array.isArray(promo.excludedGameIDs) && promo.excludedGameIDs.includes(Number(gameId))) return true;
						
						var th = this.getThumbnail(game);
						th && thumbnails.push(th);
	
						return true;
					});
				}
				thumbnailLists.push(thumbnails);
			});
		}
		return thumbnailLists;
	},
	getCategoryTourPromo(tournament, ignorePromoActive){
		if(!isObject(tournament) || !Array.isArray(tournament.promos)) return [];

		var now = Date.now();
		var targetPromos = [];
		var promos = tournament.promos;
		for(var i = 0, j = promos.length; i < j; i++){
			var promo = promos[i];

			var dateFrom = Date.UTC.apply(Date, getDateUTCParams(model.redeemLangObj(promo.dateFrom)));
			var dateTo = Date.UTC.apply(Date, getDateUTCParams(model.redeemLangObj(promo.dateTo)));
			
			if(isNaN(dateFrom) || isNaN(dateTo)){
				console.warn('One of the dates is invalid!');
				continue;
			}

			if(dateFrom < now && dateTo > now){
				targetPromos.push(promo);
			}
		}

		if(targetPromos.length === 0 && ignorePromoActive && promos.length > 0) targetPromos.push(promos[0]);
		return targetPromos;
	},
	getThumbnail(game){
		if(!game || ((!game.support.mobile && model.isMobile) || (!game.support.desktop && !model.isMobile))) return null;

		var unwrappedGameId = this.unwrapGameID(game.gameId);
		var thumbnailModel = model.gameThumbnailMap[unwrappedGameId];
		if(thumbnailModel) return thumbnailModel;

		thumbnailModel = reactive(new GameThumbnailModel(game));

		model.gameThumbnailMap[unwrappedGameId] = thumbnailModel;
		model.arrGameThumbnails.push(thumbnailModel);
		
		if(thumbnailModel.openFromTime && thumbnailModel.openInHours){
			thumbnailModel.categories.forEach(catId => {
				if(!Array.isArray(model.thsHourly[catId])) model.thsHourly[catId] = [];
				model.thsHourly[catId].push(thumbnailModel);
			});
		}

		switch(thumbnailModel.productID){
			case Enum.ProductIDs.Microgaming : 
				if(thumbnailModel.jackpot){
					this.setupGamesGlobalThumbnailJackpot(thumbnailModel);
				}
				break;
		}

		if(isObject(thumbnailModel.langPos)) model.langPosThs.push(thumbnailModel);

		return thumbnailModel;
	},
	async startGame(params){
		const module = await this.gameStartBusiness();
		module.startGame.call(this, params);
	},
	loadGameBusiness(businessItem, callback){
		if(businessItem.instance) {
			callback?.();
			return;
		}

		dynamicImportLoad(() => businessItem.loader()).then(module => {
			businessItem.instance = new module.default();
			this.gameBusinessInstances[businessItem.name] = businessItem.instance;
			model.observables.gameBusinesses.push(businessItem.instance);
			this.gameBusinesses.push(businessItem.instance);
			callback?.();
		});
	},
	destroyAllGames(){
		for(var i = 0, j = this.gameBusinesses.length; i < j; i++){
			var business = this.gameBusinesses[i];
			business.game.loaded && business.destroyGame(true);
		}
		model.dispatchEvent('UpdateCreditMeter', 'UnlockBodyScroll', 'WindowResize');
	},
	hideAllGames(){
		var atLeastOneGameHidden = false;

		for(var i = 0, j = this.gameBusinesses.length; i < j; i++){
			var business = this.gameBusinesses[i];
			if(!business.game.loaded)
				continue;

			if(business.game.displayed)
				atLeastOneGameHidden = true;
			business.hideGame();
		}
		model.dispatchEvent('UpdateCreditMeter');
		if(atLeastOneGameHidden)
			model.dispatchEvent('DisplayAllMsgs', 'GameDisplayed', 'UnlockBodyScroll', 'WindowResize');
	},
	displayGame(label){
		for(var i = 0, j = this.gameBusinesses.length; i < j; i++){
			var business = this.gameBusinesses[i];
			if(business.game.loaded)
				business.label == label ? business.displayGame() : business.hideGame();
		}
		model.dispatchEvent('UpdateCreditMeter', 'GameDisplayed', 'LockBodyScroll', 'WindowResize');
	},
	closeGame(label){
		var gameBusiness = this.gameBusinesses.find(x => x.label == label);
		if(!gameBusiness) return;

		gameBusiness.destroyGame();
		model.dispatchEvent('UpdateCreditMeter');
	},
	isAGameDisplayed(){
		return this.gameBusinesses.find(business => business.game.displayed) || false;
	},
	isAGameLoaded(){
		return this.gameBusinesses.find(business => business.game.loaded) || false;
	},
	setRedirUserAfterLogin(callback){
		this.redirectUserAfterLoginCallback = () => {
			setTimeout(() => {
				if(typeof callback === 'function') callback();
			}, 0);
		};
	},
	unsetRedirUserAfterLogin(){
		this.redirectUserAfterLoginCallback = null;
	},
	redirectUserAfterLogin(){
		if(typeof this.redirectUserAfterLoginCallback === 'function'){
			this.redirectUserAfterLoginCallback();
			this.unsetRedirUserAfterLogin();
		}
	},
	setGameAfterLogin(gameID, options){
		this.startGameAfterLoginCallback = () => {
			if(this.isGameResumeRequired()) return;

			if(!isObject(options)) options = {};
			options.gameID = gameID;

			this.startGame(options);
		}
		this.goTo(Enum.UIContexts.LOGIN);
	},
	startGameAfterLogin(){
		if(this.startGameAfterLoginCallback){
			this.startGameAfterLoginCallback();
			this.unsetGameAfterLogin();
		}
	},
	unsetGameAfterLogin(){
		this.startGameAfterLoginCallback = null;
	},
	isObjGameID(gameId){
		if(isObject(gameId)) return gameId.desktop || gameId.mobile;
		return true;
	},
	getGamesByProductID(productID){
		var res = [];
		for(var i = 0, j = model.games.length; i < j; i++){
			var game = model.games[i];
			if(game.productID == productID){
				res.push(game);
			}
		}
		return res;
	},
	getFirstGameByGroupId(groupID){
		return model.games.find(x => x.gameGroup == groupID);
	},
	getGamesByGroupIDs(groupIDs, onlyIDs){
		groupIDs = toArray(groupIDs);

		if(typeof onlyIDs !== 'boolean') onlyIDs = false;

		var res = [];
		for(var i = 0, j = model.games.length; i < j; i++){
			var game = model.games[i];
			if(groupIDs.some(id => game.gameGroup == id)){
				if(onlyIDs) res.push(this.unwrapGameID(game.gameId));
				else res.push(game);
			}
		}
		return res;
	},
	getTournThumbnails(promoId){
		var res = [];

		var gameIDs = this.getTournGameIDs(promoId);
		gameIDs.forEach(gameId => {
			var th = this.getThumbnail(this.getGameByID(gameId));
			th && res.push(th);
		});

		return res;
	},
	getGameGroupByID(gameId){
		var game = this.getGameByID(gameId);
		return game && game.gameGroup;
	},
	mapAccPromoGameIDs(promo){
		if(!promo || !Array.isArray(promo.gameIDs)) return null;
	
		return promo.gameIDs.map(item => {
			if(isObject(item)) return item.gameID;
			return item;
		});
	},
}

function configThumbnailLoadScroll(){
	var amount;
	var wh = this.isMobile ? Math.max(window.innerHeight, window.innerWidth) : ((window.screen ? window.screen.availHeight : 0) || window.innerHeight);

	if(model.isUI('FUND')){
		amount = 15;
	}
	else{
		if(wh <= 480) amount = 21;
		else if(wh <= 823) amount = 30;
		else amount = 40;
	}

	model.thumbnailScrollLoadAmount = amount;
}