export const SBInvalidUserText = { 
	"en": "You have to be a validated user to be able to invite your first friend and win {space-bus-win-amount}.",
	"el": "Πρέπει να είσαι ταυτοποιημένος παίκτης για να μπορέσεις να προσκαλέσεις τον πρώτο σου φίλο και να κερδίσεις {space-bus-win-amount}.",
	"de": "Du musst ein verifizierter Benutzer sein, um deinen ersten Freund einladen zu können und {space-bus-win-amount} zu gewinnen.",
	"tr": "İlk arkadaşınızı davet edebilmek ve {space-bus-win-amount} kazanmak için doğrulanmış bir kullanıcı olmanız gerekir.",
	"es": "tienes que ser un usuario validado para poder invitar a tu primer amigo y ganar {space-bus-win-amount}."
}

export const ViewStreamStatusLabel = { 
	"en": "Open Stream",
	"el": "Άνοιξε το Stream",
	"de": "Stream öffnen",
	"es": "Stream Abierta"
}