import model from "@js/model.js";
import { toArray } from "@helpers/utils.js"


export default {
	initTournaments(){
		model.observables.allTourIds = [];

		model.on('ListUpdated_tournaments', 'GameDisplayed', () => {
			var api = this.getExtAPI('tournaments');
			if(!api) return;

			var ids = [];
			Array.isArray(api.data) && api.data.forEach(tour => ids.push(tour.TournamentId));
			model.observables.allTourIds = ids;
		});
	},
	sendTournamentRequest (callback, global) {
		if(!this.getExtAPI('tournaments')) {
			callback?.();
			return;
		}

		this.executeAPI('tournaments', true, true, () => {
			var api = this.getExtAPI('tournaments');
			if (api && Array.isArray(api.data)) {
				if (api.data.length > 0) this.executeAPI('leaderboard', true, true, callback, global);
				else typeof callback === 'function' && callback();
			}
			else typeof callback === 'function' && callback();
		}, global);
	},
	sendTournamentRequestIfNoTours(callback, __, callbackIfExists){
		var api = this.getExtAPI('tournaments');
		if(!api){
			typeof callbackIfExists === 'function' && callbackIfExists();
			return;
		}

		if(Array.isArray(api.data)){
			typeof callbackIfExists === 'function' && callbackIfExists();
			typeof callback === 'function' && callback();
		}
		else
			this.sendTournamentRequest.apply(this, arguments);
	},
	anyLocalTourFromPromoList () {
		return model.promoList?.find(x => x.type === 'local-tournament' && this.comparePromoDateFromTo(x));
	},
	tournamentVisible(tournamentId){
		var data = this.getTournamentById(tournamentId);
		var now = Date.now();
		var dataExists = Boolean(data && data.VisibleFrom && data.VisibleTo);
		return dataExists && (data.VisibleFrom < now && data.VisibleTo > now);
	},
	tournamentStarting(tournamentId){
		var data = this.getTournamentById(tournamentId);
		var now = Date.now();
		var dataExists = Boolean(data && data.StartDate);
		return dataExists && data.StartDate > now;
	},
	tournamentStarted(tournamentId){
		var data = this.getTournamentById(tournamentId);
		var now = Date.now();
		var dataExists = Boolean(data && data.StartDate && data.EndDate);
		return dataExists && (data.StartDate < now && data.EndDate > now);
	},
	tournamentEnded(tournamentId){
		var data = this.getTournamentById(tournamentId);
		var now = Date.now();
		var dataExists = Boolean(data && data.EndDate);
		return dataExists && data.EndDate < now;
	},
	getTournamentById(tournamentId){
		var api = this.getExtAPI('tournaments');
		if(!api) return null;
		return Array.isArray(api.data) ? api.data.find(item => item.TournamentId == tournamentId) : null;
	},
	isGameInTourn(gameID){
		var bool = null;

		$.each(model.tourGames, (__, arr) => {
			for(var i = 0, j = arr.length; i < j; i++){
				if(this.evaluateGameId(gameID, arr[i])){
					bool = true;
					return false;
				}
			}
		});

		if(typeof bool === 'boolean') return bool;

		var game = this.getGameByID(gameID);
		if(!game) return false;

		$.each(model.tourGameGroupIds, (__, arr) => {
			for(var i = 0, j = arr.length; i < j; i++){
				if(game.gameGroup == arr[i]){
					bool = true;
					return false;
				}
			}
		});
		
		return bool;
	},
	isGameInTournDisplayed(tournamentIds){
		tournamentIds = toArray(tournamentIds, true);
		if(tournamentIds === null) return false;

		for(var i = 0, len = tournamentIds.length; i < len; i++){
			var tournamentId = tournamentIds[i];

			if(!model.tourGameGroupIds[tournamentId] || !model.tourGames[tournamentId]) continue;

			var game = model.tourGameGroupIds[tournamentId].find(item => checkActiveGames.call(this, 'displayed', item, 'gameGroup'));
			if(game) return true;

			game = model.tourGames[tournamentId].find(item => checkActiveGames.call(this, 'displayed', item, 'id'));
			if(game) return true;
		}

		return false;
	}
}

function checkActiveGames(propName, id, idPropName){
	for(var key in model.activeGames){
		var game = model.activeGames[key];

		var propBool = game[propName];

		if(propBool && game[idPropName] == id) return true;
	}
	return false;
}