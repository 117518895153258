import model from "@js/model.js";
import { addQueryStringValues } from "@helpers/utils.js"
import dynamicImportLoad from "@helpers/dynamic-import-load.js";

export default {
	initMaintenanceSetup(){
		if(!model.urls.maintenance?.active) return;
		
		typeof model.urls.maintenance.config === 'string' && this.webServiceSend({
			dataType: 'json',
			url: model.urls.maintenance.config,
			type: 'GET',
			cache: false,
			global: false,
			success: (data) => handleMaintenance.call(this, data),
		});
	},
}

function handleMaintenance(data){
	dynamicImportLoad(async () => {
		const { MaintenanceText, MaintenanceTitle } = await import("@lang/maintenance.js");
		model.pushLang({ MaintenanceText, MaintenanceTitle });
	}).then(() => {
		var msgFrom = new Date(data.msgFrom);
		var offline = {
			dateFrom: new Date(data.offline.dateFrom),
			dateTo: new Date(data.offline.dateTo),
		};
	
		var now = Date.now();
	
		if(offline.dateTo.getTime() < now) return;
	
		if(offline.dateFrom.getTime() < now && offline.dateTo.getTime() > now){
			window.location.replace(addQueryStringValues(model.urls.maintenance.path, {
				offlineUntil: data.offline.dateTo,
				lang: model.lang,
			}));
		}
		else if(msgFrom.getTime() < now && sessionStorage.getItem('maintenance-notified') !== 'true'){
			// let options = {
			// 	timeZoneName: 'short',
			// 	year: 'numeric', 
			// 	month: '2-digit', 
			// 	day: '2-digit', 
			// 	hour: '2-digit', 
			// 	hour12: false, 
			// 	minute:'2-digit', 
			// 	second:'2-digit',
			// };
			// let dateFrom = new Date(offline.dateFrom).toLocaleString(undefined, options);
			// let dateTo = new Date(offline.dateTo).toLocaleString(undefined, options);

			let dateFrom = new Date(offline.dateFrom).toLocaleString();
			let dateTo = new Date(offline.dateTo).toLocaleString();

			this.showNotification({
				title: model.getStr('MaintenanceTitle'),
				text: model.getStr('MaintenanceText').replace('{dateFrom}', `<b>${dateFrom}</b>`).replace('{dateTo}', `<b>${dateTo}</b>`),
				setFirst: true,
				closeDisabled: true,
				afterTerminate: () => sessionStorage.setItem('maintenance-notified', true),
			});
		}
	});
}