export const ForgotEmailOrCellPhoneText = { 
	"en": "Email or Cell Phone Number",
	"el": "E-mail ή αριθμός κινητού τηλεφώνου",
	"de": "E-Mail oder Handynummer",
	"es": "Email o Número Celular",
	"se": "E-post eller mobiltelefonnummer",
	"tr": "E-posta veya cep telefonu numarası"
}

export const InsertActivationCodeText = { 
	"en": "You have successfully registered. You can activate your account using the five (5) digit activation code that has been sent to your mobile phone or the activation link that has been sent to your email.",
	"el": "Έχεις εγγραφεί επιτυχώς. Ενεργοποίησε το λογ/σμό σου συμπληρώνοντας στο παρακάτω πεδίο τον 5ψήφιο κωδικό που έχεις λάβει στο κινητό σου ή πάτα το σύνδεσμο ενεργοποίησης που έχεις λάβει στην ηλεκτρονική σου διεύθυνση (το email που δήλωσες κατά την εγγραφή σου).",
	"de": "Deine Registrierung war erfolgreich. Du kannst dein Konto aktivieren, indem du den fünf(5)-stelligen Aktivierungscode, der an dein Handy gesendet wurde, eingibst oder den Aktivierungslink, den du per E-Mail erhalten hast, klickst.",
	"es": "Te has registrado correctamente. Puedes activar tu cuenta utilizando el código de activación de cinco (5) dígitos que se ha enviado a tu teléfono móvil o el enlace de activación que se ha enviado a tu correo electrónico.",
	"se": "Du har nu registrerat dig. Du kan aktivera ditt konto med hjälp av den femsiffriga (5) aktiveringskoden som har skickats till din mobiltelefon eller aktiveringslänken som har skickats till din e-post.",
	"tr": "Başarıyla kaydoldunuz. Hesabınızı, cep telefonunuza gönderilen beş (5) haneli etkinleştirme kodunu veya e-postanıza gönderilen etkinleştirme bağlantısını kullanarak etkinleştirebilirsiniz."
}

export const FiveDigActivCodeLabel = { 
	"en": "5 digit activation code",
	"el": "Βάλε τον πενταψήφιο κωδικό εδώ",
	"de": "5-stelliger Aktivierungscode",
	"es": "Código de activación de 5 dígitos",
	"se": "5-siffrig aktiveringskod",
	"tr": "5 haneli etkinleştirme kodu"
}

export const ActivateAccLabel = { 
	"en": "Activate account",
	"el": "Ενεργοποίηση λογαριασμού",
	"de": "Konto aktivieren",
	"es": {
		"FOUR_SG": "Activar la cuenta",
		"FUND": "Activa Tu Cuenta"
	},
	"se": "Aktivera konto",
	"tr": "Hesabı etkinleştir"
}

export const FundResetPassText = { 
	"en": "Enter the email or cell phone number you used to register and we will send you a reset link. ",
	"es": "Ingresa el correo electrónico o el número de teléfono celular que utilizaste para registrarte y te enviaremos un enlace de restablecimiento. "
}

export const FundLoginWelcomeTitle = { 
	"en": "Welcome back Fundalori!",
	"es": "¡BIENVENIDO DE NUEVO FUNDALORI!"
}

export const FundLoginTextPre = { 
	"en": "Not a Fundalori yet?",
	"es": "¿Aún no eres un Fundalori?"
}

// export const SignUpLabel = {
// 	"en": "Sign up"
// }

export const ResetPasswordLabel = { 
	"en": "Reset Password",
	"es": "Restabecer Contraseña"
}

export const TestOurGamesText = { 
	"en": "Try our games",
	"el": "Παίξε χωρίς χρήματα",
	"de": "Teste unsere Spiele",
	"es": "Prueba nuestros juegos",
	"se": "Prova våra spel!",
	"tr": "Oyunlarımızı test edin!"
}

export const RegisterNowLabel = { 
	"en": "Register now",
	"el": "Εγγραφή",
	"de": "Jetzt registrieren",
	"es": "Regístrate ahora",
	"se": "Registrera dig nu",
	"tr": "Şimdi kaydol"
}

export const LostPasswordText = {
	"en": "Forgot password?",
	"el": "Ξέχασες τον κωδικό;",
	"de": "Passwort vergessen?",
	"es": "¿Olvidaste la Contraseña?",
	"se": "Jag har glömt mitt lösenord",
	"tr": "Parolamı unuttum"
}

export const LoginTitle1 = {
	"en": "Member Login",
	"el": "Σύνδεση Μέλους",
	"de": "Mitgliederanmeldung",
	"es": "Acceso para usuarios",
	"se": "Medlemsinloggning",
	"tr": "Üye Girişi"
}

export const LoginTitle2 = {
	"en": "Not a Member?",
	"el": "Δεν είσαι Μέλος;",
	"de": "Kein Mitglied?",
	"es": "¿No eres un usuario registrado?",
	"se": "Inte medlem?",
	"tr": "Üye değil misiniz?"
}

export const Login = { 
	"FOUR_SG": {
		"en": "Login",
		"el": "Σύνδεση",
		"de": "Anmelden",
		"es": "Iniciar Sesión",
		"se": "Inloggning",
		"tr": "Giriş"
	},
	"FUND": {
		"en": "Login",
		"es": "Accede"
	}
}

export const TermsAndCondAfterLoginText = { 
	"en": "We’ve updated the Terms and Conditions.<br/>These changes will be effective on our site as of {0} so we're asking you to agree to the new terms.<br/>Please review the changes carefully. <br/>In case you don't agree, please contact Customer Support to close your account",
	"el": "Οι Όροι και Προϋποθέσεις έχουν ανανεωθεί.<br/>Οι αλλαγές αυτές θα έχουν ισχύ από {0} και σου ζητάμε να συμφωνήσεις στην καινούρια πολιτική απορρήτου. Παρακαλούμε διάβασε τις αλλαγές προσεκτικά. <br/>Σε περίπτωση που δεν συμφωνείς, επικοινώνησε με την Υποστήριξη Πελατών για το κλείσιμο του λογαριασμού σου.",
	"de": "Wir haben unsere Allgemeinen Geschäftsbedingungen aktualisiert. Diese Änderungen sind ab dem {0} auf unserer Website gültig. Wir bitten dich daher, den neuen Geschäftsbedingungen zuzustimmen. Bitte überprüfe die Änderungen sorgfältig. Falls du nicht mit ihnen einverstanden bist, wende dich bitte an unseren Kundendienst, um dein Konto zu schließen.",
	"tr": "Şart ve Koşullarımızı güncelledik. Bu değişiklikler sitemizde {0} tarihinden itibaren geçerli oldu, bu yüzden sizden yeni şartları kabul etmenizi istiyoruz. Lütfen değişiklikleri dikkatlice inceleyin.    Kabul etmemeniz durumunda, hesabınızı kapatmak için lütfen destek birimimize başvurun.",
	"es": "Hemos actualizado nuestros Términos y Condiciones. Estos cambios entraron en vigor en nuestro sitio web a partir de {0}, por lo que te pedimos que aceptes los nuevos términos. Por favor revisa los cambios detenidamente. En caso de no estar de acuerdo, ponte en contacto con nuestro soporte para cerrar su cuenta."
}

export const PrivacyPolicyAfterLoginText = { 
	"en": "We’ve updated the Privacy Policy. <br/>These changes will be effective as of {0} so we're asking you to agree to the new Privacy policy. Please review the changes carefully. <br/><br/>In case you don't agree, please contact Customer Support to close your account.",
	"el": "Η Πολιτική Απορρήτου έχει ανανεωθεί. <br/>Οι αλλαγές αυτές θα έχουν ισχύ από {0} και σου ζητάμε να συμφωνήσεις στην καινούρια Πολιτική Απορρήτου. Παρακαλούμε διάβασε τις αλλαγές προσεκτικά.  <br/>Σε περίπτωση που δεν συμφωνείς, επικοινώνησε με την Υποστήριξη Πελατών για το κλείσιμο του λογαριασμού σου.",
	"de": "Wir haben unsere Datenschutzrichtlinie aktualisiert. Diese Änderungen sind ab dem {0} auf unserer Website gültig. Wir bitten dich daher, der neuen Richtlinie zuzustimmen. Bitte überprüfe die Änderungen sorgfältig. Falls du nicht mit ihnen einverstanden bist, wende dich bitte an unseren Kundendienst, um dein Konto zu schließen.",
	"tr": "Gizlilik Politikamızı güncelledik.  Bu değişiklikler sitemizde {0} tarihinden itibaren geçerli oldu, bu yüzden sizden yeni politikaları kabul etmenizi istiyoruz. Lütfen değişiklikleri dikkatlice inceleyin.    Kabul etmemeniz durumunda, hesabınızı kapatmak için lütfen destek birimimize başvurun.",
	"es": "Hemos actualizado nuestra Política de privacidad. Estos cambios entraron en vigor en nuestro sitio web a partir de {0}, por lo que te pedimos que aceptes la nueva política. Por favor, revisa los cambios detenidamente. En caso de no estar de acuerdo, ponte en contacto con nuestro soporte para cerrar tu cuenta."
}

export const ViewTermsCondLabel = { 
	"en": "View Terms and Conditions",
	"el": "Όροι και Προϋποθέσεις",
	"de": "Allgemeine Geschäftsbedingungen einsehen",
	"tr": "Şart ve Koşulları görüntüle",
	"es": "Véanse Términos y condiciones"
}

export const ViewPrivacyPolicyLabel = { 
	"en": "View Privacy policy",
	"el": "Πολιτική Απορρήτου",
	"de": "Datenschutzpolitik",
	"tr": "Gizlilik Politikasını görüntüle",
	"es": "Véase Política de privacidad"
}

