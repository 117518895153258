import model from "@js/model.js";
import { isObject, getParameterByName, clearLocationSearch, addQueryStringValues, /*generateUniqueId,*/ addToArrayIfExists, checkTimstpBetweenCurrTimstp, removeItemFromArray } from "@helpers/utils.js"
import { computed } from "vue"
import Enum from "@shared/enum.js";

import { DepositNowLabel } from "@lang/cashier.js";
import { 
	LearnMoreLabel, 
	CashBackTC, 
	AboutPromotion, 
	PromoFullLabel, 
	PromoFullLabelSect, 
	PromoShortLabel, 
	PromoTC, 
	PromotionsGuestTitle, 
	SinglePromoLabel, 
	TourSchedule, 
	TournamentTC,
} from "@lang/promo.js";
import { ViewStreamStatusLabel } from "@lang/spaceBus.js";
import { CopyLinkLabel, InviteFriends, InviteNow, LoadLessLabel, LoadMoreLabel, ShowLessLabel, ShowMoreLabel, TermsCondLabel } from "@lang/promo.js";
import { OpenTourLB } from "@lang/gameTournament.js";

var extraPromoRequest = false;
let promoListRequesting = false;

export default {
	initWebRes(){
		model.pushLang({
			DepositNowLabel,
			LearnMoreLabel, 
			CashBackTC, 
			AboutPromotion, 
			PromoFullLabel, 
			PromoFullLabelSect, 
			PromoShortLabel, 
			PromoTC, 
			PromotionsGuestTitle, 
			SinglePromoLabel, 
			TourSchedule, 
			TournamentTC,
			ViewStreamStatusLabel,
			OpenTourLB,
			CopyLinkLabel,
			InviteFriends,
			InviteNow,
			ShowMoreLabel,
			ShowLessLabel,
			LoadMoreLabel,
			LoadLessLabel,
			TermsCondLabel,
		});

		model.observables.iframePopupLoaded = false;
		model.observables.webresShowCommonSectionBottomWave = true;
		model.observables.webresShowNav = false;

		if(!model.urls.webres) model.urls.webres = model.urls.footer;

		model.urls.webres ||= {};

		if(isObject(model.urls.promo) && model.urls.promo.basePath){
			for(var key in model.urls.promo){
				if(key !== 'basePath' && typeof model.urls.promo[key] === 'string')
					model.urls.promo[key] = model.urls.promo.basePath + model.urls.promo[key];
			}
		}

		model.hasWebRes = {
			icons: (() => {
				var r = model.urls.webres.icons;
				return Boolean(r && (r.auth || r.guest));
			})(),
			tabs: Boolean(model.urls.webres.infoTabs),
			footerContent: Boolean(model.urls.webres.content),
		};

		var webResID = getParameterByName('openWebRes');
		webResID && this.openWebResLink(model.urls.webres[webResID]);
		clearLocationSearch('openWebRes');

		model.on('SetLang', () => extraPromoRequest = true);

		model.isView(Enum.Views.MAIN) && (() => {
			if(model.isUI('FOUR_SG') || model.isUI('SKY')){
				var prevPage = model.observables.activePage;
				model.on('LogoutComplete', 'SetLang', 'OpenPage', e => {
					var bool = model.observables.activePage != Enum.Pages.PROMO_INFO ? model.observables.activePage !== prevPage : true;
					if((e.eventName === 'OpenPage' ? bool : true) && (e.eventName !== 'LogoutComplete' || !e.isPageLoad)){
						this.sendPromoListRequest();
					}
					prevPage = model.observables.activePage;
				});
			}
			else if(model.isUI('FUND')){
				if(model.multipage && model.mpPromoLoad?.route){
					(() => {
						if(this.getTokenFromStorage()){
							model.on('LoginComplete', e => {
								e.dispose();
								inner.call(this);
							});
							return;
						}

						inner.call(this);

						function inner(){
							this.sendPromoListRequest(() => {
								this.getPromoByRoute(model.mpPromoLoad.route, model.mpPromoLoad.preRoute).then(res => {
									this.loadPromoWin({
										id: res.promo ? res.promo.id : model.mpPromoLoad.route,
										multipageCheck: false,
									});
								});
							}, undefined, true);
						}
					})();
				}
				else {
					model.on('LogoutComplete', 'SetLang', e => {
						let global = model.currCatIds.level1 !== Enum.CATEGORY_IDS.MAINHOME;
						(e.eventName !== 'LogoutComplete' || !e.isPageLoad) && this.sendPromoListRequest(null, global);
					});
				}
			}
		})();

		model.bannerDataConfigured = false;
	},
	webResBusiness(global){
		return this.getBusiness('__webResBusiness', () => import("../business/web-res-business.js"), global);
	},
	displayHtmlContent(title, htmlContent){
		if (model.isUI('FUND')) {
			model.webResInfo = {
				title: title,
				text: htmlContent,
			};
			this.gotoCategory({
				level1: Enum.CATEGORY_IDS.WEBRES,
			});
			return;
		}

		this.goTo(Enum.UIContexts.INFO_MSG, false, {
			title: title,
			text: htmlContent,
		});
	},
	loadRespGamingWebRes(){
		this.webResBusiness().then(module => {
			module.loadRespGamingWebRes.apply(this, arguments);
		});
	},
	openFooterLink(id, label, multipageCheck){
		var link = model.urls.webres[id];

		if(model.multipage){
			var path = model.redeemLangObj(link.path);
			if(typeof path === 'string'){
				if(typeof multipageCheck !== 'boolean') multipageCheck = true;
				if(multipageCheck){
					this.multipageRedir([path], true);
					return;
				}
			}
		}

		this.openWebResLink(link, label);
	},
	openWebResLink(link, label){
		if(!isObject(link)) return;

		if(typeof link.callback === 'function'){
			link.callback.apply({
				model: this.model,
				controller: this,
			}, arguments);
		}
		else if(typeof link.url === 'string') this.sendWebResRequest(link.url);

		//google analytics
		model.dispatchEvent('InfoOpened', {
			name: model.getStr(label, 'en'),
		});
	},
	openIFramePopup(params){
		model.observables.iframePopupLoaded = true;
		this.whenComponentReady('IframePopup').then(() => model.dispatchEvent('IFramePopupOn', params));
	},
	openTournamentTC(tournamentId){
		if(!Array.isArray(model.promoList) || !model.promoList.length){
			this.sendPromoListRequest(() => this.openTournamentTC.apply(this, arguments), true);
			return;
		}

		var promo = model.promoList.find(item => item.tournamentId === tournamentId);
		promo && this.loadPromoWin(promo.id);
	},
	loadPromoWin(){
		let args = Array.prototype.slice.call(arguments);
		args.push(extraPromoRequest);
		this.webResBusiness().then(module => {
			module.loadPromoWin.apply(this, args);
		});
	},
	getPromoById(id){
		var targetPromo = null;
		var tourLength = Array.isArray(model.tourIds) && model.tourIds.length;
		for(var i = 0, j = model.promoList.length; i < j; i++){
			var promo = model.promoList[i];

			var clonedPromoFound = false;
			if(tourLength){
				for(var tourInd = 0; tourInd < tourLength; tourInd++){
					if((id + '_' + model.tourIds[tourInd]) === promo.id){
						clonedPromoFound = true;
						break;
					}
				}
			}

			if(clonedPromoFound || id == promo.id){
				targetPromo = promo;
				break;
			}
		}

		return targetPromo;
	},
	async getPromoByRoute(){
		return (await this.webResBusiness()).getPromoByRoute.apply(this, arguments);
	},
	sendFooterRequest(){
		this.webResBusiness(false).then(module => {
			module.sendFooterRequest.apply(this, arguments);
		})
	},
	sendFooterIconsRequest(){
		this.webResBusiness(false).then(module => {
			module.sendFooterIconsRequest.apply(this, arguments);
		})
	},
	sendFooterTabsRequest(){
		this.webResBusiness().then(module => {
			module.sendFooterTabsRequest.apply(this, arguments);
		})
	},
	sendPromoListRequestIfEmpty(callback){
		if(promoListRequesting){
			model.on('PromoListLoaded', e => {
				e.dispose();
				callback?.();
			})
		}
		else if(!Array.isArray(model.promoList) || !model.promoList.length){
			this.sendPromoListRequest.apply(this, arguments);
		}
		else if(typeof callback === 'function') callback();
	},
	sendPromoListRequest: (() => {
		let callbacks = [];

		return function(callback, global, waitoutForTours){
			if(typeof callback === 'function') callbacks.push(callback);

			this.whenAppReady(() => {
				if(typeof global !== 'boolean') global = model.isUI('FUND');
				if(typeof waitoutForTours !== 'boolean') waitoutForTours = false;
				
				if(!model.urls.promo || !model.urls.promo.content) return;
				
				if(promoListRequesting) return;

				extraPromoRequest = false;

				if(!this.webWorker) return;

				model.bannerData = null;
				model.bannerDataConfigured = false;
	
				model.on('WorkerPromoList', e => {
					e.dispose();
	
					model.bannerData.pages = this.sortPromosAndBanners(model.bannerData.pages, true);
					model.dispatchEvent('BannerAdded', 'PromoListLoadedPre');

					if(model.promoList.find(x => x.tournamentId && x.dateFrom && x.dateTo && checkTimstpBetweenCurrTimstp(x.dateFrom, x.dateTo))){
						let ignore = false;
						let timeout;

						if(!waitoutForTours){
							timeout = setTimeout(() => {
								ignore = true;
								inner.call(this);
							}, 2000);
						}
						
						this.sendTournamentRequest(() => {
							clearTimeout(timeout);
							!ignore && inner.call(this);
						}, global);
					}
					else inner.call(this);

					function inner(){
						configurePromoList.call(this);
						this.setupPromoModels();

						while(callbacks.length > 0){
							callbacks.pop()();
						}
						
						model.dispatchEvent('PromoListLoaded');
						promoListRequesting = false;
					}
				});
	
				var url = addQueryStringValues(model.urls.promo.content.replace('{lang}', model.lang), {
					cache: Date.now(),
				});
	
				if(url.substring(0, 4).toLowerCase() !== 'http') url = (model.urls.workerPrefix || '') + url;

				promoListRequesting = true;

				this.sendToWorker('get-promo-list', {
					params: {
						url: url,
						cdnPath: model.cdnPath,
						logged: model.logged,
						showPromosGuest: model.showPromosGuest,
					}
				});
			});
		};
	})(),
	sendWebResRequest(){
		this.webResBusiness().then(module => {
			module.sendWebResRequest.apply(this, arguments);
		})
	},
	loadNotification(path, global){
		return new Promise(resolve => {
			this.webServiceSend({
				dataType: 'script',
				url: path,
				type: 'GET',
				cache: false,
				contentType: null,
				global,
				success: () => resolve(),
			});
		});
	},
	sortPromosAndBanners(arr, isBanner){
		if(typeof isBanner !== 'boolean') isBanner = false;

		var typeComingSoon = arr.filter(item => {
			if(item.tournamentId){
				var data = this.getTournamentById(item.tournamentId);
				if(data && typeof data.StartDate === 'number')
					return Date.now() < data.StartDate;
			}
			else if(typeof item.dateStartsIn === 'number')
				return Date.now() < item.dateStartsIn;
		});

		var typeLocalTours = arr.filter(item => item.type === 'local-tournament' && !typeComingSoon.includes(item));
		var typeNetworkTours = arr.filter(item => item.type === 'network-tournament' && !typeComingSoon.includes(item));
		var typePromotion = arr.filter(item => (item.type === 'promotion' || item.type === 'spinbooster') && !typeComingSoon.includes(item));
		var other = arr.filter(item => typeof item.type !== 'string' && !typeComingSoon.includes(item));

		typeLocalTours.sort(handlePromoBannerSort);
		typeNetworkTours.sort(handlePromoBannerSort);
		typePromotion.sort(handlePromoBannerSort);
		typeComingSoon.sort(handlePromoBannerSort);
		other.sort(handlePromoBannerSort);

		typeLocalTours.sort((item1, item2) => {
			if(typeof item1.priority === 'number' && typeof item2.priority === 'number'){
				if(item1.priority < item2.priority) return 1;
				else if(item1.priority > item2.priority) return -1;
			}
			return 0;
		});

		typeLocalTours = handlePrioritySort.call(this, typeLocalTours);
		typeNetworkTours = handlePrioritySort.call(this, typeNetworkTours);

		let concatedAll = typeLocalTours.concat(typeNetworkTours).concat(typePromotion).concat(typeComingSoon);
		let res = (model.logged || !isBanner) ? concatedAll.concat(other) : other.concat(concatedAll);

		let topPriorities = res.filter(x => typeof x.topPriority === 'number');
		topPriorities.forEach(item => removeItemFromArray(res, item));
		topPriorities.sort((item1, item2) => item1.topPriority - item2.topPriority);

		return topPriorities.concat(res);
	}
};

function configurePromoList(){
	if(!Array.isArray(model.promoList)) return;

	// var predefinedIdPrefix = generateUniqueId();
	// var tournamentsExist = false;

	var tourPromosToClone = [];

	for(var i = 0, j = model.promoList.length; i < j; i++){
		var promo = model.promoList[i];
		
		if(promo.id === Enum.Promos.FREQ_PLAYER && (model.siteId === Enum.SiteIDs.NET_4SC || model.siteId === Enum.SiteIDs.SKY_IO) && model.userProfile?.country === 'IN'){
			model.promoList.splice(i, 1);
			i--;
			j--;
			continue;
		}

		if(promo.id == Enum.Promos.SPACE_BUS && !model.referral){
			model.promoList.splice(i, 1);
			i--;
			j--;
			continue;
		}

		if(typeof promo.loadPromoTCPage !== 'boolean') promo.loadPromoTCPage = true;

		promo.ctaBtns = [];

		//todo, needs better fix
		if(promo.id == Enum.Promos.FIRST_DEPOSIT || promo.id == Enum.Promos.RAKE_BACK){
			promo.depositNow = false;
		}

		if(promo.depositNow){
			promo.ctaBtns.push({
				isDeposit: true,
				form: false,
				offers: true,
			});
		}

		if(promo.registerNow && !model.logged){
			promo.ctaBtns.push({
				label: model.isUI('FUND') ? 'JoinNowLabel' : 'RegisterNow',
				form: false,
				offers: true,
				onClick: () => this.goTo(Enum.UIContexts.REGISTER)
			});
		}

		promo.btns = model.redeemLangObj(promo.btns);

		if(Array.isArray(promo.btns)){
			promo.btns.forEach(btn => {
				if(btn.auth && !model.logged) return;

				if(btn.openCat) btn.onClick = () => this.gotoCategory({level2: btn.openCat});
				if(btn.openCatLevel1) btn.onClick = () => this.gotoCategory({level1: btn.openCatLevel1})

				promo.ctaBtns.push(btn);
			});
		}

		switch(promo.id){
			case Enum.Promos.FREQ_PLAYER : 
				if(model.isUI('FOUR_SG') || model.isUI('SKY')){
					promo.ctaBtns.push({
						label: 'ViewStreamStatusLabel',
						form: true,
						offers: true,
						onClick: () => this.goTo(Enum.UIContexts.BONUS_POINTS),
					});
				}
				break;
			case Enum.Promos.SPACE_BUS : 
				(() => {
					if(model.isUI('FUND')){
						promo.ctaBtns.push({
							label: 'InviteNow',
							form: false,
							offers: true,
							onClick: () => onInviteFriendsClick.call(this),
						});
						return;
					}

					if(model.logged && model.userValid()){
						promo.ctaBtns.push({
							label: 'CopyLinkLabel',
							form: true,
							offers: false,
							clipboard: {
								text: model.spaceBusLink,
							},
						});
					}

					promo.ctaBtns.push({
						label: 'InviteFriends',
						form: !model.userValid(),
						offers: false,
						onClick: () => onInviteFriendsClick.call(this),
					});

					function onInviteFriendsClick(){
						// if(!model.logged){
						// 	this.goTo(Enum.UIContexts.LOGIN, true);
						// 	this.setRedirUserAfterLogin(() => {
						// 		model.on('PromoListLoaded', e => {
						// 			this.loadPromoWin(Enum.Promos.SPACE_BUS);
						// 			e.dispose();
						// 		});
						// 	});
						// }
						// else if(!model.userValid()) this.showSpaceBusIllegitimateMsg();
						// else this.loadPromoWin(Enum.Promos.SPACE_BUS);
						this.goTo(Enum.UIContexts.REFERRAL_PAGE);
					}
				})();
				break;
			// case Enum.Promos.RAKE_BACK : 
			// 	promo.ctaBtns.push({
			// 		isDeposit: true,
			// 		offers: true,
			// 		form: true,
			// 	})
			// 	break;
			case Enum.Promos.FIRST_DEPOSIT : 
				(() => {
					let btnProps = {
						isDeposit: true,
						offers: true,
					};

					if(model.isUI('FUND')) {
						btnProps.form = false;
						btnProps.disabled = computed(() => {
							return model.observables.deposits > 0;
						});
					}
					else btnProps.form = true;

					promo.ctaBtns.push(btnProps);
				})();
				break;
		}

		let notif = model.promoNotifList[(promo.id + '').replace('campaign_', '')];
		if(notif?.noDeposit){
			promo.ctaBtns.push({
				id: 'WofLaunch',
				form: true,
				offers: false,
				label: 'PlayNowLabel',
				visible: {value: false},
				onClick: () => this.openWOF(),
			});
		}

		if(getParameterByName('TestRemoveTourIDFromLTs') == 'true') delete promo.tournamentId;

		if(promo.tournamentId){
			if(!this.getExtAPI('leaderboard') || !this.getExtAPI('tournaments')){
				model.promoList.splice(i, 1);
				i--;
				j--;
			}
			else{
				var tourId = promo.tournamentId;
				var isTourIdArr = Array.isArray(tourId);
				if(isTourIdArr){
					var originalId = promo.id;
					tourId.forEach(id => {
						tourPromosToClone.push({
							promo: promo,
							tourId: id,
							originalId: originalId,
						});
					});
				}

				promo.visible = false;
				// tournamentsExist = true;
				if(!isTourIdArr && Array.isArray(model.categoryTours)){
					var categoryTour = model.categoryTours.find(item => item.tournamentId == tourId);
					promo.accostPlayNowClickId = categoryTour && categoryTour.clickId;
					addPromoPlayNowBtn.call(this, promo);
				}
				else if(promo.overrideAccostPlayNowBtnBehavior && promo.accostPlayNowClickId){
					addPromoPlayNowBtn.call(this, promo);
				}
			}
		}
		else{
			addPromoPlayNowBtn.call(this, promo);
			setupCtaBtns.call(this, promo);
		}
	}
		
	var promosThatWereCloned = [];

	tourPromosToClone.forEach(item => {
		if(!promosThatWereCloned.includes(item.promo))
			promosThatWereCloned.push(item.promo);

		var clonedPromo = $.extend(true, {}, item.promo);
		clonedPromo.id = item.originalId + '_' + item.tourId;
		clonedPromo.tournamentId = item.tourId;
		addTourCtaBtns.call(this, clonedPromo);
		
		if(this.tournamentStarted(item.tourId) && clonedPromo.accostPlayNowClickId){
			var categoryTour = model.categoryTours.find(accItem => item.tourId == accItem.tournamentId);
			if(categoryTour){
				clonedPromo.accostPlayNowClickId = categoryTour.clickId;
				addPromoPlayNowBtn.call(this, clonedPromo);
			}
		}

		model.promoList.push(clonedPromo);

		setupCtaBtns.call(this, clonedPromo);
	});

	promosThatWereCloned.forEach(promo => {
		var ind = model.promoList.indexOf(promo);
		ind !== -1 && model.promoList.splice(ind, 1);
	});

	model.promoList = this.sortPromosAndBanners(model.promoList, false);

	function setupCtaBtns(promo){
		promo.ctaBtns[model.isUI('FUND') ? 'push' : 'unshift']({
			isLearnMore: true,
			offers: true,
			form: false,
		});

		(promoId => {
			promo.ctaBtns.forEach(btn => {
				if(btn.isLearnMore){
					btn.label = 'LearnMoreLabel';
					btn.onClick = () => {
						if(promo.route && promo.type === 'promotion') sessionStorage.setItem(`forced-promo-by-route_${promo.route}`, promoId);

						if(typeof promo.url === 'string'){
							this.multipageRedir([promo.url], true);
							return;
						}

						if(typeof promo.loadPromo === 'string'){
							this.loadPromoWin(promo.loadPromo);
							return;
						}

						this.loadPromoWin(promoId);
					}
				}
				else if(btn.isDeposit){
					btn.label = 'DepositNowLabel';
					btn.onClick = () => this.goTo(Enum.UIContexts.DEPOSIT)
				}
			});
		})(promo.id);
	}
}

function addPromoPlayNowBtn(promo){
	if(!promo.accostPlayNowClickId || !model.isTournament(promo)) return;
	if((promo.dateEndsIn && promo.dateEndsIn < Date.now()) || (promo.dateStartsIn && promo.dateStartsIn > Date.now())) return;

	addToArrayIfExists(promo, 'ctaBtns', [
		{
			label: 'PlayNowLabel',
			form: false,
			offers: true,
			isPlayNow: true,
			onClick: () => {
				if(model.isUI('FUND')){
					sessionStorage.setItem('promo-page-scroll-to-games', true);

					if(typeof promo.loadPromo === 'string'){
						this.loadPromoWin(promo.loadPromo);
						return;
					}

					this.loadPromoWin(promo.id);
					return;
				}

				EGOE.focusCategoryTour(promo.accostPlayNowClickId);
			},
		},
	], !model.isUI('FUND'));
}

function addTourCtaBtns(promo){
	if(model.isUI('FUND')) return;

	var ctaBtns = [
		{
			label: 'OpenTourLB',
			form: false,
			offers: true,
			onClick: () => {
				this.loadPromoWin({
					id: promo.id,
					redirToLB: true,
				});
			},
		},
	];
	addToArrayIfExists(promo, 'ctaBtns', ctaBtns, true);
}

function handlePrioritySort(arr){
	var arrPriority = [];
	arr = arr.filter(item => {
		var hasPriority = typeof item.priority === 'number';
		hasPriority && arrPriority.push(item);
		return !hasPriority;
	});
	arrPriority.sort(onPrioritySort);
	return arrPriority.concat(arr);
}

function onPrioritySort(item1, item2){
	if(typeof item1.priority === 'number' && typeof item2.priority === 'number'){
		if(item1.priority < item2.priority) return -1;
		else if(item1.priority > item2.priority) return 1;
		return 0;
	}
}

function handlePromoBannerSort(item1, item2){
	if(item1.dateStartsIn && item2.dateStartsIn){
		var date1 = item1.dateStartsIn;
		var date2 = item2.dateStartsIn;
		return date1 - date2;
	}
	if(item1.dateFrom && item2.dateFrom){
		var date1 = item1.dateFrom;
		var date2 = item2.dateFrom;
		return date1 - date2;
	}
	if(item1.tournamentId && item2.tournamentId) return item1.tournamentId - item2.tournamentId;
}