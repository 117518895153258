import model from "@js/model.js";
import controller from "@js/controller/controller.js";
export default {
	data(){
		return {
			mountedModals: [],
		}
	},
	methods: {
		_initModalHandler(){
			let scrollBeforeModal = 0;

			$(document).on('beforeshowbsmodal', () => {
				if (model.isMobile) scrollBeforeModal = $(document).scrollTop();
			});

			$(document).on('shownbsmodal', (e) => {
				let modal = model.observables.modals.find(x => x.uiContext == $(e.target).data('ui-context'));
				if(!modal) return;

				modal.visible = true;

				model.dispatchEvent('CloseNavMenu', 'HideMobileNav');

				model.isMobile && (this.modalDisplayed = true);

				!model.isMobile && model.dispatchEvent('LockBodyScroll');
				model.dispatchEvent('HideAllMsgs');

				if(model.isMobile) $(document).scrollTop(0);
			});

			$(document).on('hiddenbsmodal', e => {
				let modal = model.observables.modals.find(x => x.uiContext == $(e.target).data('ui-context'));
				if(!modal) return;

				modal.shown = false;

				if (!modal.preserved) {
					controller.removeModal(modal);
					controller.loadPrevModal();
				}

				modal.preserved = false;
				var anyModals = controller.anyModals();

				if (model.isMobile && !anyModals) {
					this.modalDisplayed = false;
					setTimeout(() => $(document).scrollTop(scrollBeforeModal), 0);
				}

				model.dispatchEvent('UnlockBodyScroll', 'WindowResize');
				model.dispatchEvent('ModalClosed', {modal});
				!anyModals && model.dispatchEvent('DisplayAllMsgs');
			});

			if(!model.isUI('FUND') && model.isMobile){
				model.on('ModalMounted', e => {
					if(!this.mountedModals.includes(e.modal)) this.mountedModals.push(e.modal);
				});

				model.on('ModalDestroyed', e => {
					if(this.mountedModals.includes(e.modal)) this.mountedModals.splice(this.mountedModals.indexOf(e.modal), 1);
				});
			}
		}
	},
};