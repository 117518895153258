import model from "@js/model.js";
import { watch } from "vue"
export default {
	initBlog(){
		model.observables.blogArticleId = null;
		model.observables.blogCatsFetched = false;
	},
	blogBusiness(){
		return this.getBusiness('__blogBusiness', () => import("../business/blog-business.js"));
	},
	getBlogs(){
		blogBusiness().then(module => module.getBlogs.apply(this, arguments));
	},
	getBlogPosts(){
		blogBusiness().then(module => module.getBlogPosts.apply(this, arguments));
	},
	getBlogArticle(){
		blogBusiness().then(module => module.getBlogArticle.apply(this, arguments));
	},
	whenBlogCatsFetched(callback){
		if(model.observables.blogCatsFetched) {
			callback();
			return;
		}

		let unwatch = watch(() => model.observables.blogCatsFetched, bool => {
			if(!bool) return;
			callback();
			unwatch();
		});
	},
}