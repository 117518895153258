import model from "@js/model.js";

export default function(dynamicImportPromise, global){
    if(typeof global !== 'boolean') global = true;
    return new Promise(resolve => {
        model.dynamicImports++;
        global && model.dispatchEvent('ShowPreloader');
        dynamicImportPromise().then(result => {
            model.dynamicImports--;
            model.dispatchEvent('HidePreloader');
            resolve(result);
        });
    });
};