export default {
	set(cname, cvalue, exdays) {
		var info = [];

		var name = cname + "=" + cvalue;
		info.push(name);

		if(exdays){
			var d = new Date();
			d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
			info.push("expires=" + d.toUTCString());
		}

		info.push("path=/");

		var hostSplit = location.host.split('.');
		if(hostSplit.length >= 2)
			info.push("domain=." + hostSplit.splice(hostSplit.length - 2, 2).join('.'));
		document.cookie = info.join(';');
	},
	get(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for(var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}
}