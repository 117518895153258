import model from "@js/model.js";
import { propNameLowerCase, getParameterByName, arrayFilter } from "@helpers/utils.js"
import Enum from "@shared/enum.js";

import cookieHelper from "@helpers/cookie-helper.js";

export default {
	initNotification(){
		model.promoNotifList = {};
		model.observables.notifHandlerLoaded = false;

		model.on('GoTo', () => {
			this.anyModals() && model.dispatchEvent('HideAllMsgs');
		});

		model.observables.notifOpened = false;

		model.on('removeQueuedMsgByID', 'RemoveAllQueuedMsgs', 'DisplayAllMsgs', 'HideAllMsgs', 'ShowMsg', 'CloseMsg', e => {
			var args = e.args;
			model.observables.notifHandlerLoaded = true;
			this.whenComponentReady('NotificationHandler').then(() => {
				var propName = propNameLowerCase(e.eventName);
				this.notificationHandler[propName].apply(this.notificationHandler, args);
			});
		});

		window.testFetchNotification = (id, params, global) => {
			if(typeof global !== 'boolean') global = true;
			if(params?.promotionId) {
				let url = model.urls.lobbyHelperPromo?.replace('{id}', params.promotionId);
				if(url){
					this.webServiceSend({
						url,
						type: 'GET',
						global: true,
						dataType: 'json',
						contentType: false,
						processData: false,
						success: json => {
							params.gameId = params.gameId || json.gameIds;
							this.getNotification.call(this, id, params);
						}
					});
					return;
				}
			}

			this.getNotification.call(this, id, params);
		}

		model.on('PromoListLoaded', () => {
			if(model.logged || this.getTokenFromStorage()) return;

			for(let promoId in model.promoNotifList){
				let notif = model.promoNotifList[promoId];
				if(notif.noDeposit) {
					if(cookieHelper.get('no-dep-promo-' + promoId)) return;

					let promo = model.promos.all.find(x => x.id === 'campaign_' + promoId);
					let validStartDate = promo && promo.dateStartsIn && promo.dateEndsIn && promo.dateStartsIn < Date.now() && promo.dateEndsIn > Date.now();
					if(getParameterByName('testguestpromomsgs') === 'true' || validStartDate){
						this.getNotification(43, {promotionId: promoId, guestMessage: true}, {avoidPromoListReq: true});
						cookieHelper.set('no-dep-promo-' + promoId, true, 365);
					}
				}
			}
		});
	},
	notificationBusiness(global){
		return this.getBusiness('__notificaitonBusiness', () => import("../business/notification-business.js"), global);
	},
	getNotification(infoContentId, params, options){
		return new Promise(resolve => {
			options = options || {};
	
			if(typeof options.avoidPromoListReq !== 'boolean') options.avoidPromoListReq = false;
	
			var paramsStr = '';
			for(var key in params) paramsStr += key + ':' + params[key] + '|';
			paramsStr = paramsStr.substring(0, paramsStr.lastIndexOf('|'));
	
			if(!options.avoidPromoListReq) this.sendPromoListRequest(() => inner.call(this), true);
			else inner.call(this);
	
			function inner(){
				this.fetchPreparedGames(() => {
					this.notificationBusiness(options.global).then(module => {
						module.fetchNotification.call(this, [
							{
								notification: {
									infoContentId: infoContentId,
									parameters: paramsStr,
								},
							},
						], null, options).then(res => resolve(res));
					});
				}, true);
			}
		});
	},
	showNotification() {
		model.dispatchEvent('ShowMsg', {
			args: arguments,
		});
	},
	closeNotification() {
		model.dispatchEvent('CloseMsg');
	},
	showClientMessage() {
		this.notificationBusiness().then(module => {
			module.showClientMessage.apply(this, arguments);
		});
	},
	loadClientMessageContent(){
		let global = arguments[4];
		this.notificationBusiness(global).then(module => {
			module.loadClientMessageContent.apply(this, arguments);
		});
	},
	handleServerNotification (action) {
		if((model.activeGames?.['egoe']?.hasFreeGames) || model.view !== Enum.Views.MAIN) return;

		if(action.actionType == 'UserLogin' && [Enum.UIContexts.CASHIER, Enum.UIContexts.ACCOUNT].includes(model.initialContext)){
			sendPromoListAfterLoginComplete.call(this);
			model.initialContext = null;
			model.initialSubContext = null;
			return;
		}

		let notifications = arrayFilter(action.messages.message, msg => msg.type == 'Notification');

		model.notificationLength = notifications.length;
		if(!model.urls.notifications || !model.notificationLength){
			action.actionType == 'UserLogin' && sendPromoListAfterLoginComplete.call(this);
			return;
		}

		let firstPromoNotif = notifications.find(x => x.notification?.infoContentId == 43);
		model.x1NotifRecieved = notifications.find(x => x.notification?.infoContentId == 47);

		this.notificationBusiness().then(module => {
			if(firstPromoNotif || action.actionType == 'UserLogin') sendPromoListAfterLoginComplete.call(this, () => {
				this.fetchPreparedGames(() => module.fetchNotification.call(this, notifications));
			}, true);
			else module.fetchNotification.call(this, notifications);
		});
		function sendPromoListAfterLoginComplete(){
			if(model.logged){
				this.sendPromoListRequest.apply(this, arguments);
				return;
			}

			model.on('LoginComplete', e => {
				e.dispose();
				this.sendPromoListRequest.apply(this, arguments);
			});
		}
	},
	async redirByPointType(){
		(await this.notificationBusiness()).redirByPointType.apply(this, arguments);
	},
	async loadNotificationsFromUserLogin(){
		(await this.notificationBusiness()).loadNotificationsFromUserLogin.apply(this, arguments);
	},
}