export default class EventDispatcher {
	constructor() {
		this.events = {};
	}

	on(){return this.#addEventListener.apply(this, arguments)}
	off(){return this.#removeEventListener.apply(this, arguments)};

	addEventListener(){return this.#addEventListener.apply(this, arguments)}
	removeEventListener(){return this.#removeEventListener.apply(this, arguments)};

	async dispatchEvent(){
		let self = this;
		var args = Array.prototype.slice.call(arguments);
		for(var i = 0, j = args.length; i < j; i++){
			var key = args[i];
			if(typeof key !== 'string') continue;

			var events = this.events[key];
			if(!events) continue;

			for(var eventInd = 0, eventsLength = events.length; eventInd < eventsLength; eventInd++){
				var eventCallback = events[eventInd];
				if(typeof eventCallback === 'function'){
					var dataObj = args[j - 1];
					dataObj = typeof dataObj === 'object' && dataObj != null ? dataObj : {};
					dataObj.currentTarget = this;
					dataObj.eventName = key;
					dataObj.dispose = ((key, cb) => {
						return function(){
							self.off(key, cb);
							eventInd--;
							eventsLength--;
							Array.prototype.slice.call(arguments).forEach(extraKey => {
								key !== extraKey && self.off(extraKey, cb);
							});
						};
					})(key, eventCallback);
					let resp = eventCallback(dataObj);
					if(resp instanceof Promise) await resp;
				}
			}
		}
	}

	#addEventListener() {
		var args = Array.prototype.slice.call(arguments);
		var len = args.length;
		var callback = args[len - 1];
		for(var i = 0; i < len; i++){
			var key = args[i];
			if(typeof key === 'string'){
				if(!this.events.hasOwnProperty(key))
					this.events[key] = [];
				this.events[key].push(callback);
			}
			else if(Array.isArray(key)){
				args.splice.apply(args, [i, 1].concat(key));
				len += key.length - 1;
				i--;
			}
		}
		return callback;
	};

	#removeEventListener() {
		var args = Array.prototype.slice.call(arguments);
		var len = args.length;
		var lastItem = args[len - 1]; //can be a callback or string
		if(typeof lastItem === 'string'){
			if(this.events.hasOwnProperty(lastItem)) delete this.events[lastItem];
			return;
		}

		for(var i = 0; i < len; i++){
			var key = args[i];
			if(typeof key === 'string'){
				if(this.events.hasOwnProperty(key)) {
					var _events = this.events[key];
					for (var ei = 0, el = _events.length; ei < el; ei++) {
						if (_events[ei] === lastItem) {
							_events.splice(ei, 1);
							ei--;
							el--;
						}
					}
				}
			}
			else if(Array.isArray(key)){
				args.splice.apply(args, [i, 1].concat(key));
				len += key.length - 1;
				i--;
			}
		}
	};
};