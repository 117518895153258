import model from "@js/model.js";
import { arrayFirst } from "@helpers/utils.js"


var timeout = null;

export default  {
	initKeepAlive(){
		model.on('ServerResponseSuccess', e => {
			switch(e.action.actionType){
				case 'KeepAlive':
					this.handle_keepAlive(e.action);
					model.dispatchEvent('KeepAliveResponse');
					this.handleJackpot(e.$xml);
					break;
			}
		});

		model.on('LogoutComplete', 'StopKeepAlive', () => stop.call(this));
		model.on('LoginComplete', 'KeepAliveResponse', 'StartKeepAlive', () => send.call(this));
	},
	getGameGroups(action){
		var msgEvents = arrayFirst(action.messages.message, msg => msg.type == 'Events' && msg.keepAlive);
		if(!msgEvents) return;

		var keepAlive = msgEvents.keepAlive;
		var gameGroups = keepAlive.credits && keepAlive.credits.gameGroup;
		var gameGroupList = {};

		if(Array.isArray(gameGroups)){
			for(var i = 0, j = gameGroups.length; i < j; i++){
				var gGroup = gameGroups[i];
				gameGroupList[gGroup.id] = gGroup.val;
			}
		}

		return gameGroupList;
	},
	handle_keepAlive(action) {
		var msgEvents = arrayFirst(action.messages.message, msg => msg.type == 'Events' && msg.keepAlive);
		if(!msgEvents || !model.mainAccount) return;

		var keepAlive = msgEvents.keepAlive;
		model.mainAccount.gameCredits = keepAlive.cVal;
		model.mainAccount.bonusPoints = keepAlive.bonusPoints;
		model.mainAccount.gameGroups = this.getGameGroups(action);
		model.dispatchEvent("UpdateCreditMeter", "UpdateChatKeepAlive");
	},
	sendKeepAliveRequest(){
		stop.call(this);
		sendKeepAlive.call(this);
	},
}

function stop(){
	clearTimeout(timeout);
};

function send(){
	stop.call(this);
	if(!model.keepAliveConfig || typeof model.keepAliveConfig.serverUpdateDelay !== 'number' || !model.logged) return;
	timeout = setTimeout(() => sendKeepAlive.call(this), model.keepAliveConfig.serverUpdateDelay);
};

function sendKeepAlive(){
	this.sendRequest('KeepAlive', null, null, {error: xhr => xhr.status == 0 && send.call(this)});
}