const NUM_OF_MONTHS = 12;
const NUM_OF_DAYS = 31;
const NUM_OF_YEARS = 100;

export function generateDays() {
    let arr = [];
    for (let i = 0; i < NUM_OF_DAYS; i++) arr.push(addZero(i + 1));
    return arr;
}

export function generateMonths() {
    let arr = [];
    for (let i = 0; i < NUM_OF_MONTHS; i++) arr.push(addZero(i + 1));
    return arr;
}

export function generateYears(numYears, ascending) {
    if(typeof ascending !== 'boolean') ascending = false;

    if(!numYears) numYears = NUM_OF_YEARS;

    let arr = [];
    let currYear = (new Date()).getFullYear();

    if(ascending) for (var i = 0; i < numYears; i++) arr.push(currYear + i);
    else {
        let lastYear = currYear - numYears;
        for (let year = currYear; year >= lastYear; year--) arr.push(year);
    }

    return arr;
}

export function addZero(int){
    return int < 10 ? ('0' + int) : int;
}

export function getFilterProps(){
    return {
        day: {value: null},
        month: {value: null},
        year: {value: null},
    };
}