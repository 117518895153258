import { nextTick } from "vue"
import Enum from "@shared/enum.js";
import model from "../model.js";
import controller from "../controller/controller.js";

export default function(){
	model.vue.app.directive('scroll-lock', {beforeMount});
	
	var initialized = false;
	
	function beforeMount(element){
		if(initialized)
			throw new Error('ScrollLock should be binded only once.');
	
		initialized = true;
	
		/*
			Note: Keep in mind that ScrollLock binding handler can 
			drastically slow down the performance of certain features 
			that only appear on mobile devices (e.g. GameFrameMobile and
			user notifications). Henceforth, we should keep it disabled on
			mobile devices. The performance problem is noticable especially 
			on Safari. 
			*/
		if(model.isMobile) return;
	
		var $container = $(element);
		$container.addClass('scroll-lock');
		
		model.scrollLockedTop = 0;
	
		model.on('LockBodyScroll', () => {
			if(shouldUnlock()){
				handleScrollUnlock(model.scrollLockedTop);
				return;
			}
	
			if(!model.scrollLocked){
				model.scrollLockedTop = $(document).scrollTop() * -1;
				handleScrollLock(model.scrollLockedTop);
			}
			
			model.dispatchEvent('WindowResize');
		});
	
		model.on('UnlockBodyScroll', () => {
			nextTick(() => {
				shouldUnlock() && handleScrollUnlock(model.scrollLockedTop);
			});
		});
	
		model.on('LogoutComplete', () => {
			setTimeout(function(){
				if(model.scrollLocked && model.getCurrContext() == Enum.UIContexts.LOGIN){
					var tmpScrollTop = ($container.outerHeight(true) - window.innerHeight) * -1;
					model.scrollLockedTop = Math.min(Math.abs(tmpScrollTop), Math.abs(model.scrollLockedTop)) * -1;
					handleScrollLock(model.scrollLockedTop);
				}
			}, 0);
		});
	
		function handleScrollLock(topPosition){
			$container.css({
				'position': 'fixed',
				'top': topPosition + 'px',
			});
			model.scrollLocked = true;
		}
	
		function handleScrollUnlock(topPosition){
			if(!model.scrollLocked) return;
	
			$container.css({
				'position': '',
				'top': '',
			});
		
			$(document).scrollTop(Math.abs(topPosition));
			model.scrollLocked = false;
			model.scrollLockedTop = 0;
			model.dispatchEvent('WindowResize');
		}
	
		function shouldUnlock(){
			return (
				!controller.getActiveModal() && 
				!controller.isAGameDisplayed() && 
				!model.observables.notifOpened &&
				!model.observables.selectedMiniGame
			);
		}
	}
}