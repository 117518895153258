import model from "@js/model.js";
import { clearLocationSearch, getParameterByName } from "@helpers/utils.js"
import { reactive } from "vue"

import { FiltersLabel } from "@lang/user.js";

export default {
	initFilters(){
		model.pushLang({FiltersLabel});

		model.observables.filterCompLoaded = false;
		model.observables.filterOpenedOnce = false;
		model.observables.filterDisplayed = false;
		model.observables.filterOn = false;

		model.filterPropsShared = reactive({
			searchByNameInput: '',
			onSearchInputClear: () => {
				model.filterPropsShared.searchByNameInput = '';
			},
		});

		model.on('OpenFilter', e => {
			e.dispose();
			var navAttached = e.navAttached;

			this.fetchPreparedGames(() => {
				this.whenComponentReady('GameFilter').then(() => {
					model.observables.filterCompLoaded = true;
					model.dispatchEvent('OpenFilter', {navAttached});
				});
				model.observables.filterOpenedOnce = true;
			});
		});

		if(typeof model.filterActive !== 'boolean') model.filterActive = true;

		model.filterAutoProps = {};
		
		var filterProvider = getParameterByName('filterprovider');
		if(filterProvider) model.filterAutoProps.providers = filterProvider;

		var filterFeature = getParameterByName('filterfeature');
		if(filterFeature) model.filterAutoProps.feature = filterFeature;

		if(model.filterAutoProps?.providers){
			clearLocationSearch('filterprovider');
			this.whenAppReady(() => {
				model.dispatchEvent('OpenFilter');
			});
		}
	},
}