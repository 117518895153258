import model from "@js/model.js";
import { isObject } from "@helpers/utils.js"
import { reactive } from "vue"
import Enum from "@shared/enum.js";

export default {
	initX1(){
		model.x1 = reactive({
			exists: false,
			disabled: false,
			urgent: false,
			label: null,
		});

		model.on('LoginComplete', 'LogoutComplete', 'UserProfileLoaded', 'UpdateLimits', () => updateX1ExistsState.call(this));

		model.on('GetPlayerInfoLoaded', () => {
			updateX1ExistsState.call(this);
			if(!model.x1.exists || !isObject(model.deposit)) return;
			model.x1.urgent = model.deposit.paymentProviderId == Enum.PPIDs.X1;
		});

		model.on('LoginComplete', () => {
			if(!model.x1.exists) return;
			if(localStorage.getItem('x1-popup-no-show') == 'true') return;
			this.x1Business().then(module => module.showPopup.call(this));
		});
	},
	x1Business(){
		return this.getBusiness('__x1Business', () => import('../business/x1-business.js'));
	},
	openX1Page(){
		this.x1Business().then(module => module.openX1Page.call(this));
	},
}

function updateX1ExistsState(){
	model.x1.exists = (
		typeof model.urls.x1 === 'string' && 
		model.mainAccount && 
		model.mainAccount.hasX1Token /*&& 
		model.userProfile && 
		model.userProfile.currency === 'EUR'*/
	);
	model.x1.disabled = !model.canDeposit() || model.hasDepositLimitActive();
	model.x1.label = (
		model.userProfile && 
			['1000000000000010801', '1100000000000002966', '1300000000000002876', '1300000000000001681'].includes(model.userProfile.partnerId) ? 'Buy Crypto' : 'Deposit CC');
}