import model from "@js/model.js";
import { isObject } from "@helpers/utils.js"
import Enum from "@shared/enum.js";


let initialState = null;

let forcedObservables = {
	max: {},
	count: {},
	resolveCallback: null,
	timeout: null,
};

window.testForcedObservables = function(){
	return forcedObservables;
}

export default {
	initPopState(){
		if(model.view == Enum.Views.CASHIER) return;
	
		var modalPopState = false;

		model.isUI('FUND') && model.on('OpenFilter', e => {
			!e.isPopState && !model.observables.filterDisplayed && addState({
				type: 'filter'
			});
		});
	
		model.on('GoToCategory', e => {
			if(e.isPopState) return;

			addState({
				type: 'category',
				ids: e.ids,
				forceScroll: e.forceScroll,
				scroll: e.scroll,
				promoId: e.promoId,
			}, e.userInteracted);
		});
	
		model.on('GoTo', () => {
			!modalPopState && addState({
				type: 'modal',
			});
			modalPopState = false;
		});

		model.on('OpenGameFrame', () => {
			addState({
				type: 'gameFrame',
			});
		});
	
		window.addEventListener('popstate', () => {
			var state = history.state;

			if(!state) state = initialState;

			window.currentState = state;

			if(!isObject(state)) return;

			if(model.isUI('FUND') && model.observables.filterDisplayed){
				model.dispatchEvent('OpenFilter', {
					isPopState: true,
				});
				return;
			}

			if(model.gameFrameDisplayed){
				model.dispatchEvent('CloseGameFrame');
				return;
			}

			if(this.anyModals()){
				this.activeModals.length > 1 && (modalPopState = true);
				this.closeActiveModal();
				return;
			}

			switch(state.type){
				case 'category' : 
					if(state.promoId){
						this.loadPromoWin({
							id: state.promoId,
							isPopState: true,
						});
					}
					else this.gotoCategory(state.ids, state.forceScroll, state.srcoll, true);
					break;
			}
		});
	},
}

function addState(params, userInteracted){
	if(typeof userInteracted !== 'boolean') userInteracted = true;

	if(!userInteracted){
		initialState = JSON.parse(JSON.stringify(params));
		return;
	}
	
	window.currentState = params;
	history.pushState(params, 
	document.title, 
	window.location.pathname + window.location.search);
}