import model from "@js/model.js";

export default function(element, binding){
	var props = binding.value;
	var $elem = $(element);

	if(typeof props !== 'object'){
		if(typeof props !== 'undefined'){
			props = {
				value: props
			};
		}
		else{
			props = {};
		}
	}

	props = $.extend({
		shouldFormat: true,
		includeDecimals: true,
		currencyCode: null,
		includeSymbol: true,
		centsSeparator: null,
		thousandsSeparator: null,
	}, props);

	var value;
	if(props.shouldFormat && (typeof props.value === 'string' || typeof props.value === 'number'))
		value = model.formatAsCurrency(props);
	else
		value = Number(String(props.value).replace(/[^0-9]/g, ''));
	$elem.html(value);
}