export const AlreadyFundaloriPreText = {
	"en": "Already a Fundalori?",
	"es": "¿Ya eres un Fundalori?"
}

export const FundRegisterTitleSubtext = {
	"en": "Join the no-wagering<br>revolution!",
	"es": "¡Únete a la revolución<br>de sin rollover!"
}

export const FundAcceptTermsCondText = {
	"en": "I am at least 18 years old and I accept the {terms-cond} and {privacy}. ",
	"es": "TENGO AL MENOS 18 AÑOS Y ACEPTO LOS {terms-cond} Y LA {privacy}. "
}

export const FundAcceptNewsletters = {
	"en": "newsletter",
	"es": "Quiero recibir ofertas y promociones por email"
}

export const PhoneCodeInput = { 
	"en": "Code"
}

export const CellPhoneNumInput = {
	"en": "Cell Phone Number",
	"es": "Número Celular"
}

export const FinalStepLabel = { 
	"en": "Final Step",
	"es": "Paso Final"
}

export const NameSurnameReg = { 
	"en": "Please use latin characters.",
	"el": "Χρησιμοποίησε λατινικούς χαρακτήρες.",
	"de": "Verwende bitte lateinische Zeichen.",
	"es": "Por favor usa caracteres latinos."
}

export const RegisterLatinChars = { 
	"en": "Please use latin characters and numbers.",
	"el": "Χρησιμοποίησε λατινικούς χαρακτήρες και αριθμούς.",
	"de": "Verwende bitte lateinische Zeichen und Zahlen.",
	"es": "Por favor usa caracteres latinos y números."
}

export const SelCurrencyCCodeError = { 
	"en": "The selected currency is not compatible with the country of your choice.",
	"el": "Το επιλεγμένο νόμισμα δεν είναι συμβατό με τη χώρα επιλογής.",
	"de": "Die gewählte Währung ist nicht mit dem ausgewählten Land kompatibel.",
	"es": "La moneda seleccionada no es compatible con el país de tu elección."
}

export const BirthdayLabel = { 
	"en": "Birthday",
	"el": "Ημερομηνία Γέννησης",
	"de": "Geburtstag",
	"es": "Fecha de nacimiento",
	"se": "Födelsedag",
	"tr": "Doğum günü"
}

// export const HavePromoCodeLabel = {
// 	"en": "If you have a promotional code, click here",
// 	"el": "Πάτα εδώ αν έχεις κωδικό προσφοράς ",
// 	"de": "Ich habe einen Promocode",
// 	"es": "Si tienes un Código promocional, click aquí."
// }

export const RegisterLabel = { 
	"en": "Register",
	"el": "Εγγραφή",
	"de": "Registrieren",
	"es": "Registro",
	"se": "Registrera dig",
	"tr": "Kayıt"
}

export const JoinNowLabel = { 
	"en": "Join Now",
	"es": "Únete Ahora"
}

export const RegisterTitle = { 
	"en": "Member Registration",
	"el": "Εγγραφή Μέλους",
	"de": "Mitglieder-Registrierung",
	"es": "Registro de usuario",
	"se": "Medlemsregistrering",
	"tr": "Üye kaydı"
}

export const AcceptTerms = { 
	"en": "I accept the Terms & Conditions",
	"el": "Αποδέχομαι τους Όρους & Προϋποθέσεις",
	"de": "Ich akzeptiere die Geschäftsbedingungen",
	"es": "Acepto los términos y condiciones",
	"se": "Jag godkänner reglerna och villkoren",
	"tr": "Şart ve Koşulları kabul ediyorum"
}

export const AcceptPrivacy = { 
	"en": "I accept the Privacy Policy",
	"el": "Αποδέχομαι την Πολιτική Απορρήτου",
	"de": "Ich akzeptiere die Datenschutzpolitik",
	"es": "Acepto la política de privacidad",
	"se": "Jag godkänner sekretesspolicyn",
	"tr": "Gizlilik Politikasını kabul ediyorum"
}

export const LogInLabel = { 
	"en": "Login",
	"el": "Είσοδος",
	"de": "Anmelden",
	"es": "Accede",
	"se": "Inloggning",
	"tr": "Giriş"
}

export const LostPasswordLabel = { 
	"en": "Forgot Password?",
	"el": "Ξέχασες τον κωδικό;",
	"de": "Passwort vergessen?",
	"es": "¿Olvidaste tu contraseña?"
}

export const YourPhoneText = { 
	"en": "Your cell phone number",
	"el": "Αριθμός κινητού τηλεφώνου",
	"de": "Deine Handynummer",
	"es": "Número de teléfono",
	"se": "Ditt mobiltelefonnummer",
	"tr": "Cep telefonu numaranız"
}

export const CreateAccText = { 
	"en": "Create Account",
	"el": "Δημιούργησε λογαριασμό",
	"de": "Konto erstellen",
	"es": "Regístrate",
	"se": "Skapa konto",
	"tr": "Hesap oluştur"
}

export const RegSubmitStep_0 = { 
	"en": "Second Step",
	"es": "Segundo Paso"
}

export const RegSubmitStep_1 = { 
	"en": "Third Step",
	"es": "Tercer Paso"
}

export const UsernameOrEmailInput = { 
	"en": "Username or Email",
	"el": "Όνομα χρήστη ή Email",
	"de": "Benutzename oder E-mail",
	"es": "Nombre de Usario o E-mail"
}

export const UsernameInput = { 
	"en": "Username",
	"el": "Όνομα χρήστη",
	"de": "Dein Benutzername",
	"es": "Usuario",
	"se": "Ditt användarnamn",
	"tr": "Kullanıcı adınız"
}

export const PasswordLabel = { 
	"en": "Password",
	"el": "Κωδικός πρόσβασης",
	"de": "Dein Passwort",
	"es": "Contraseña",
	"se": "Ditt lösenord",
	"tr": "Parolanız"
}

export const CurrentPasswordLabel = { 
	"en": "Current password", 
	"es": "Contraseña actual"
}

export const OldPasswordLabel = { 
	"en": "Old password",
	"el": "Παλιός κωδικός πρόσβασης",
	"de": "Altes Passwort",
	"tr": "Eski parola",
	"es": "Contraseña anterior"
}

export const ConfirmPasswordLabel = { 
	"en": "Confirm password",
	"el": "Επαλήθευση κωδικού",
	"de": "Passwort bestätigen",
	"es": "Confirmar contraseña",
	"se": "Bekräfta lösenord",
	"tr": "Parolayı onayla"
}

export const EmailLabel = { 
	"en": "Email",
	"el": "Email",
	"de": "Email",
	"es": "Email",
	"se": "E-post",
	"tr": "E-posta"
}

export const FirstNameLabelBreak = {
	"en": "First<br>Name",
	"el": "Όνομα",
	"de": "Vorname",
	"es": "Nombre",
	"se": "Förnamn",
	"tr": "İsim"
}

export const FirstNameLabel = { 
	"en": "First Name",
	"el": "Όνομα",
	"de": "Vorname",
	"es": "Nombre",
	"se": "Förnamn",
	"tr": "İsim"
}

export const LastNameLabelBreak = { 
	"en": "Last<br>Name",
	"el": "Επώνυμο",
	"de": "Nachname",
	"es": "Apellido",
	"se": "Efternamn",
	"tr": "Soyisim"
}

export const LastNameLabel = { 
	"en": "Last Name",
	"el": "Επώνυμο",
	"de": "Nachname",
	"es": "Apellido",
	"se": "Efternamn",
	"tr": "Soyisim"
}

export const CityLabel = { 
	"en": "City",
	"el": "Πόλη",
	"de": "Stadt",
	"es": "Ciudad",
	"se": "Stad",
	"tr": "Şehir"
}

export const AddressLabel = {	 
	"en": "Address",
	"el": "Διεύθυνση",
	"de": "Adresse",
	"es": "Dirección",
	"se": "Adress",
	"tr": "Adres"
}

export const PromoCodeInput = {
	"en": "Promotional code?",
	"el": "Προωθητικός κωδικός;",
	"de": "Promocode?",
	"es": "¿Codigo promocional?",
	"se": "Kampanjkod?",
	"tr": "Promosyon kodu?"
}

export const Gender = {
	"en": "Gender",
	"el": "Φύλο",
	"de": "Geschlecht",
	"es": "Género",
	"se": "Gender",
	"tr": "Cinsiyet"
}

// export const RegistrationLabel = {
// 	"en": "Registration",
// 	"el": "Εγγραφή",
// 	"de": "Registrierung",
// 	"es": "Suscripción",
// 	"se": "Registrering",
// 	"tr": "Kayıt"
// }

// export const RegisterSuccessLabel = {
// 	"en": "Registration successful",
// 	"el": "Επιτυχής εγγραφή",
// 	"de": "Registrierung erfolgreich",
// 	"es": "Suscripción",
// 	"se": "Registrering",
// 	"tr": "Kayıt"
// }

// export const RegisterSuccessText = {
// 	"en": "We have just sent you an activation link by e-mail and SMS. Please, follow this link to activate your account. If you cannot find our email in your inbox, please check the spam/junk folder.",
// 	"el": "Μόλις στείλαμε ένα link ενεργοποίησης μέσω email και SMS. Ακολούθησε αυτό το link για να ενεργοποιήσεις τον λογαριασμό σου. Εάν δε βρίσκεις το email στα «εισερχόμενα», έλεγξε το φάκελο «spam/junk/ανεπιθύμητα».",
// 	"de": "Wir haben dir soeben einen Aktivierungslink per e-mail und SMS geschickt. Bitte klicke den Link um dein Konto zu aktivieren. Wenn du die e-mail nicht finden kannst, prüfe den Spamordner.",
// 	"es": "Acabamos de enviarte un enlace de activación por correo electrónico y SMS. Por favor, pincha en este enlace para activar tu cuenta. Si no puedes encontrar nuestro correo electrónico en tu bandeja de entrada, verifica la carpeta de correo no deseado."
// }

export const RegisterReviewDataText = {
	"en": "After registration you will not be able to change your personal data. Please review your data:",
	"el": "Μετά την εγγραφή σου, δεν θα είσαι σε θέση να αλλάξεις τα προσωπικά σου δεδομένα. Παρακαλούμε να ελέγξεις προσεκτικά τα δεδομένα σου:",
	"de": "Nach der Registrierung kannst du deine persönlichen Daten nicht ändern. Bitte überprüfe deine Daten:",
	"es": "Después del registro no podrás cambiar tus datos personales. Por favor revisa tus datos:",
	"se": "Efter registreringen kommer du inte att kunna ändra dina personuppgifter. Vänligen granska dina uppgifter:",
	"tr": "Kayıt olduktan sonra kişisel verilerinizi değiştiremezsiniz. Lütfen verilerinizi gözden geçirin:"
}

export const UsernameLabel = {
	"en": "Username",
	"el": "Όνομα χρήστη",
	"de": "Benutzername",
	"es": "Usuario",
	"se": "Användarnamn",
	"tr": "Kullanıcı Adı"
}

export const CurrencyLabel = { 
	"en": "Currency",
	"el": "Νόμισμα",
	"de": "Währung",
	"es": "Moneda",
	"se": "Valuta",
	"tr": "Para Birimi"
}

export const PhoneOnlyLabel = { 
	"en": "Phone",
	"es": "Teléfono"
}

export const PhoneLabel = { 
	"en": "Phone Number",
	"el": "Κινητό τηλέφωνο",
	"de": "Telefon",
	"tr": "Telefon",
	"es": "Teléfono"
}

export const PhoneLabelFund = { 
	"en": "Phone Number",
	"es": "CELULAR"
}

// export const PhoneLabelNo00AndPlus = {
// 	"en": "Phone Number (including your country code without + or leading zero)",
// 	"el": "Κινητό τηλέφωνο",
// 	"de": "Telefon (inkl. dein Ländercode, ohne + oder Null)",
// 	"tr": "Telefon",
// 	"es": "Teléfono"
// }

export const Change = { 
	"en": "Change",
	"el": "Αλλαγή",
	"de": "Ändern",
	"es": "Cambiar",
	"se": "Change",
	"tr": "Değiştir"
}

export const Register = { 
	"FOUR_SG": {
		"en": "Register",
		"el": "Εγγραφή",
		"de": "Registrieren",
		"es": "Registro",
		"se": "Registrera dig",
		"tr": "Kayıt"
	},
	"FUND": {
		"en": "Create Account",
		"es": "Crear Cuenta"
	}
}

export const UsernameValidChars = {
	"en": "Please use [a-z], [A-Z], [0-9] characters. Optionally, you can insert at most 3 of the following special characters: '-', '_', and '@'.",
	"el": "Χρησιμοποίησε λατινικούς χαρακτήρες και αριθμούς. Προαιρετικοί ειδικοί χαρακτήρες που επιτρέπονται: \"-\", \"_\", \"@\".",
	"de": "Verwende bitte die Zeichen [a-z], [A-Z], [0-9]. Alternativ, kannst du maximal 3 der folgenden Sonderzeichen nutzen: '-', '_', und '@'.",
	"es": "Utiliza los caracteres [az], [AZ], [0-9]. Opcionalmente, puedes insertar como máximo 3 de los siguientes caracteres especiales: '-', '_' y '@'.",
	"se": "Vänligen använd tecknen [a-z], [A-Z], [0-9]. Alternativt kan du använda högst 3 av följande specialtecken: ”-”, ”_” och ”@”.",
	"tr": "Lütfen [a-z], [A-Z], [0-9] karakterlerini kullanın. İsteğe bağlı olarak, aşağıdaki özel karakterlerden en fazla 3 tanesini ekleyebilirsiniz: '-', '_' ve '@'."
}

export const M = {
	"en": "Male",
	"el": "Άνδρας",
	"de": "Männlich",
	"es": "Hombre",
	"se": "Herr",
	"tr": "Sayın"
}

export const F = {
	"en": "Female",
	"el": "Γυναίκα",
	"de": "Weiblich",
	"es": "Mujer",
	"se": "Fru",
	"tr": "Sayın"
}

export const register_username_error = {
	"FOUR_SG": {
		"en": "Username must be 6-18 characters long!",
		"el": "Το όνομα χρήστη πρέπει να περιέχει 6-18 χαρακτήρες.",
		"de": "Der Benutzername muss 6-18 Zeichen lang sein!",
		"es": "¡El nombre de usuario debe tener entre 6 y 18 caracteres!"
	},
	"FUND": {
		"en": "Must be 6-18 characters long.",
		"es": "Debe tener entre 6 y 18 caracteres."
	}
}

export const register_phone_error = {
	"FOUR_SG": {
		"en": "Cell phone number must be in this format: {ccode} 9996 7319",
		"el": "Ο αριθμός κινητού τηλεφώνου πρέπει να είναι της μορφής: {ccode} 99967319",
		"de": "Die Handynummer muss dem Format {ccode} 9996 7319 entsprechen.",
		"es": "El número de teléfono móvil debe estar en este formato: {ccode} 9996 7319"
	},
	"FUND": {
		"en": "Must be in this format: +000 00000000",
		"es": "Debe tener este formato: +000 00000000"
	}
}

export const register_email_error = {
	"FOUR_SG": {
		"en": "Email must be in this format: mail@email.com",
		"el": "Το email πρέπει να είναι της μορφής: mail@email.com",
		"de": "Die E-Mail-Adresse muss dem Format mail@email.com entsprechen.",
		"es": "El correo electrónico debe estar en este formato: mail@email.com"
	},
	"FUND": {
		"en": "Must be in this format: mail@email.com",
		"es": "Debe estar en este formato: mail@email.com"
	}
}

export const AtMostCharsErr = {
	"en": "Please insert at most {0} characters.",
	"el": "Παρακαλούμε να εισάγεις μέχρι {0} χαρακτήρες.",
	"de": "Bitte gib höchstens {0} Zeichen ein.",
	"tr": "Lütfen en fazla {0} karakter ekleyin.",
	"es": "Por favor, introduce como máximo {0} caracteres."
}

export const AtLeastAndMostCharsErr = {
	"en": "Please insert at least {0} and at most {1} characters.",
	"de": "Bitte gib mindestens {0} und höchstens {1} Zeichen ein.",
	"tr": "Lütfen en az {0} ve en fazla {1} karakter girin.",
	"es": "Por favor, introduce al menos {0} y como máximo {1} caracteres.",
	"el": "Παρακαλούμε να εισάγεις ελάχιστο {0} και μέγιστο {1} χαρακτήρες."
}

export const NumValMinLength = {
	"en": "Please insert a value between {0} and {1}.",
	"de": "Bitte gib einen Wert zwischen {0} und {1} ein.",
	"tr": "Lütfen {0} ve {1} arasında bir değer girin.",
	"es": "Por favor inserta un valor entre {0} y {1}.",
	"el": "Παρακαλούμε να εισάγεις μία τιμή μεταξύ {0} και {1}."
}

export const InputFieldRequired = { 
	"FOUR_SG": {
		"en": "Please fill this mandatory field.",
		"el": "Το πεδίο είναι υποχρεωτικό.",
		"de": "Bitte fülle dieses Pflichtfeld aus.",
		"tr": "Lütfen bu zorunlu alanı doldurun.",
		"es": "Por favor, rellena este campo obligatorio."
	},
	"FUND": {
		"en": "Field is required.",
		"es": "Este campo es obligatorio."
	}
}

export const register_date_error = { 
	"en": "You have to be at least {0} to register.",
	"el": "Θα πρέπει να είσαι τουλάχιστον {0} για να μπορέσεις να εγγραφείς.",
	"de": "Du musst mindestens {0} sein, um dich registrieren zu können.",
	"es": "Tienes que tener al menos {0} años para poder suscribirte.",
	"se": "Du måste vara åtminstone {0} för att kunna registrera dig.",
	"tr": "Kaydolmak için en az {0} yaşında olmalısınız."
}

export const AlreadyMemberLabel = { 
	"en": "Are you already a member? Login.",
	"el": "Είσαι ήδη μέλος; Συνδέσου.",
	"de": "Bist du schon Mitglied? Anmelden.",
	"tr": "Zaten üye misiniz? Giriş yapın.",
	"es": "¿Ya eres miembro? Iniciar sesión."
}

export const CompleteRegisterLabel = { 
	"en": "Complete registration",
	"el": "Ολοκλήρωση εγγραφής",
	"de": "Registrierung abschließen",
	"tr": "Kaydı tamamla",
	"es": {
		"FOUR_SG": "Completar suscripción",
		"FUND": "ACTIVACIÓN DE CUENTA"
	}
}

export const PhoneWithoutPlusAnd00 = {
	"en": "Phone format should not start with either “+“ or “00“.",
	"el": "Η μορφή του τηλεφώνου δε θα πρέπει να ξεκινάει με “+” ή “00”.",
	"de": "Die Telefonnummer sollte nicht mit \"+\" noch mit \"00\" beginnen.",
	"es": "El formato del teléfono no debe comenzar con \"+\" o \"00\"."
}

export const PleaseCheckBoxToProceed = { 
	"FOUR_SG": {
		"en": "Please check this box if you want to proceed.",
		"el": "Επίλεξε το κουτάκι εάν επιθυμείς να προχωρήσεις.",
		"de": "Bitte aktiviere dieses Kästchen falls du fortfahren möchten.",
		"es": "Por favor, marca esta casilla si deseas continuar.",
		"se": "Vänligen kryssa i den här rutan om du vill fortsätta.",
		"tr": "Devam etmek istiyorsanız lütfen bu kutuyu işaretleyin."
	},
	"FUND": {
		"en": "Check this box to proceed.",
		"es": "Marque esta casilla para continuar."
	}
}

export const password_error = { 
	"FOUR_SG": {
		"en": "Password must have 6 to 20 characters, and it must contain at least one digit from 0-9.",
		"el": "Ο κωδικός πρόσβασης πρέπει να έχει 6 έως 20 χαρακτήρες και πρέπει να περιέχει τουλάχιστον ένα ψηφίο 0-9.",
		"de": "Das Passwort muss aus 6 bis 20 Zeichen bestehen und muss mindestens eine Ziffer von 0-9 enthalten.",
		"es": "La contraseña debe tener de 6 a 20 caracteres, y debe contener al menos un dígito de 0-9.",
		"se": "Lösenordet måste bestå av 6 till 20 tecken och det måste innehålla minst en siffra från 0-9.",
		"tr": "Parola 6 ila 20 karakterden oluşmalı ve 0-9 rakamları arasından en az bir rakam içermelidir."
	},
	"FUND": {
		"en": "Must be 6-20 characters long and include at least one digit and one letter.",
		"es": "Debe tener entre 6 y 20 caracteres e incluir al menos un número y una letra."
	}
}

export const password_error_confirm = { 
	"en": "Passwords are not the same.",
	"el": "Ο κωδικός δεν είναι ίδιος.",
	"de": "Die Passwörter sind nicht gleich.",
	"es": "Las contraseñas no son las mismas.",
	"se": "Lösenorden är inte desamma.",
	"tr": "Parolalar aynı değil."
}

export const register_email = { 
	"FOUR_SG": {
		"en": "Emails must be in mail@email.com format and cell phone numbers must be in {ccode}-99967319 format (always include the country code).",
		"el": "Τα email πρέπει να είναι της μορφής mail@email.com και τα νούμερα κινητών τηλεφώνων της μορφής {ccode}-99967319 (πάντα να συμπεριλαμβάνεται ο κωδικός χώρας)",
		"de": "E-Mails müssen im Format mail@email.com und Handynummern im Format {ccode}-99967319 (immer unter Angabe der Landesvorwahl) angegeben werden.",
		"es": "Los correos electrónicos deben estar en formato mail@email.com y los números de teléfono móvil deben estar en formato {ccode}-99967319 (siempre incluye el código del país)."
	},
	"FUND": {
		"en": "Email must be in format mail@email.com and cell phone in format +000-00000000.",
		"es": "El email debe estar en formato mail@email.com y el teléfono en formato +000-00000000."
	}
}