//fixes scrolling in chrome
// EGOE.iScrollCommonProps = {
// 	disablePointer: true,
// 	disableTouch: false,
// 	disableMouse: true,
// };

export default {
	disablePointer: true,
	disableTouch: false,
	disableMouse: true,
}