import model from "@js/model.js";
export default class LangHandler {
	constructor(model, controller) {
		this.model = model;
		this.controller = controller;

		this.langElems = [];

		model.on('LangElemAdded', e => this.#addLangElem(e.node));
		model.on('LangElemRemoved', e => removeDeprecatedNodes.call(this, e.node));
		model.on('SetLang', e => this.#setLang());

		function removeDeprecatedNodes(node) {
			if (!$(node).data('lang'))
				return;

			/*
				Improves performance for checking
				from the latest entries (since the latest
				entries are at the end of the array and
				are more likely to be removed).
			*/
			for (var i = this.langElems.length - 1; i >= 0; i--) {
				var targetNode = this.langElems[i];
				if (targetNode === node) {
					this.langElems.splice(i, 1);
					break;
				}
			}
		}
	}

	#addLangElem(node){
		var $elem = $(node);
		this.setLangToNodes($elem, model.getStr($elem.data('lang')));
		this.langElems.push(node);
	}
	
	#setLang() {
		for(var i = 0, j = this.langElems.length; i < j; i++){
			var $elem = $(this.langElems[i]);
			this.setLangToNodes($elem, model.getStr($elem.data('lang')));
		}
	}
	
	setLangToNodes($elem, text){
		//some elements might share the same lang label 
		//that aren't necessarily the same type
		if($elem.is(':input') && !$elem.is('button')){
			if($elem.attr('type') == 'button')
				$elem.val(text);
			else
				$elem.attr('placeholder', text);
		}
		else $elem.html(text);
	}
}