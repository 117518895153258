export default {
	REELS: {
		"en": "Reels",
		"es": "Rodillos",
		"el": "ΤΡΟΧΟΙ",
		"de": "Rollen",
	},

	ROWS: {
		"en": "Rows",
		"es": "Filas",
		"el": "ΣΕΙΡΕΣ",
		"de": "Reihen",
	},

	MINBET: {
		"en": "MinBet",
		"es": "AP. MIN",
		"el": "MinBet",
		"de": "Min-Einsatz",
	},

	MAXBET: {
		"en": "MaxBet",
		"es": "AP. MAX",
		"el": "MaxBet",
		"de": "Max-Einsatz",
	},

	FREESPINS: {
		"en": "FreeSpins",
		"es": "TIR. GRATIS",
		"el": "FreeSpins",
		"de": "Freispiele",
	},

	WINLINES: {
		"en": "WinLines",
		"es": "Líneas",
		"el": "WinLines",
		"de": "Gewinnlinien",
	},

	BONUS: {
		"en": "BONUS",
		"el": "ΜΠΟΝΟΥΣ",
		"de": "Bonus",
		"es": "Bono",
	},

	JACKPOT: {
		"en": "Jackpot",
		"el": "ΤΖΑΚΠΟΤ",
		"de": "Jackpot",
		"es": "Jackpot",
	},

	WAYS: {
		"en": "Ways",
		"es": "Caminos",
		"el": "Ways",
		"de": "Wege",
	},

	CLUSTERS: {
		"en": "Clusters",
		"es": "Grupos",
		"el": "Clusters",
		"de": "Ansammlungen",
	},

	HYPERCLUSTERS: {
		"en": "Hyper Clusters",
		"el": "Hyper Clusters",
		"es": "Hyper Clusters",
		"de": "Hyer-Ansammlung",
	},

	ROWWIN: {
		"en": "RowWin",
		"el": "RowWin",
		"es": "RowWin",
		"de": "Gewinnreihe",
	},

	YesLabel: {
		"en": "Yes",
		"es": "Si",
		"el": "ΝΑΙ",
		"de": "Ja",
	},

	NoLabelTh: {
		"en": "No",
		"el": "ΟΧΙ",
		"de": "Nein",
		"es": "No",
	}
}