import model from "../model.js";

export default function(){
	var $pages = $();
	
	model.on('OpenPage', function(e){
		var $activePage = $pages.filter('.active-page');
		$activePage.removeClass('active-page');
		$pages.filter('[data-page="' + e.page + '"]').addClass('active-page');
		model.observables.activePage = e.page;
		model.dispatchEvent('UpdateActiveTab');
	});
	
	model.vue.app.directive('page', {
		beforeMount(element, binding){
			var $page = $(element);
			var id = binding.value;
			if(!id) throw 'Page ID is not defined!';
	
			$page.attr('data-page', id);
			model.isMobile && $page.addClass('mobile');
			if(model.isPage(id)){
				$pages.filter('.active-page').removeClass('active-page');
				$page.addClass('active-page');
			}
			$pages = $pages.add($page);
		},
	});
}