import Enum from "@shared/enum.js";
import { firstIfUndefined, getKey, isObject, toArray } from "./utils.js";

export function setupModule(){
    this.UIID_KEYS = Object.keys(Enum.UIIDs);
    this.SITE_ID_KEYS = Object.keys(Enum.SiteIDs);

    this.UIID_KEYS.forEach((uiidKey) => {
        this.SITE_ID_KEYS.forEach((siteIdKey) => {
            if(uiidKey == siteIdKey) throw 'UIID and SiteID keys should not be the same. ';
        });
    });

    this.allLangs = ['en', 'el', 'es', 'pe', 'de', 'se', 'tr'];
}

export function redeemLangObj(obj, forcedDevice, avoidFirstIfUndefined, propsToAvoid){
    if(typeof avoidFirstIfUndefined !== 'boolean') avoidFirstIfUndefined = false;

    var mobile = this.isMobile;
    if(forcedDevice == 'mobile') mobile = true;
    else if(forcedDevice == 'desktop') mobile = false;

    obj = unwrapObjDev.call(this, obj, mobile, avoidFirstIfUndefined, propsToAvoid);
    if(isObject(obj) && Object.keys(obj).find(x => this.allLangs.includes(x))){
        if(avoidFirstIfUndefined) obj = obj[this.langKey] || obj[this.lang];
        else obj = firstIfUndefined.apply(null, [obj, this.langKey, this.lang]);
    }
    obj = unwrapObjDev.call(this, obj, mobile, avoidFirstIfUndefined, propsToAvoid);

    return obj;
}

export function unwrapObjDev(obj, mobileCheck, avoidFirstIfUndefined, propsToAvoid){
    if(typeof avoidFirstIfUndefined !== 'boolean') avoidFirstIfUndefined = false;
    if(typeof mobileCheck !== 'boolean') mobileCheck = this.isMobile;

    propsToAvoid = toArray(propsToAvoid);

    while(isObjAppropriate.call(this, obj, propsToAvoid)){
        if(obj.hasOwnProperty('prod') && obj.hasOwnProperty('test')){
            obj = obj[this.isProd ? 'prod' : 'test'];
        }
        else if(obj.hasOwnProperty('app') && obj.hasOwnProperty('web')){
            obj = obj[this.isApp ? 'app' : 'web'];
        }
        else if(obj.hasOwnProperty('ios') && obj.hasOwnProperty('pc')){
            obj = obj[this.isIOS ? 'ios' : 'pc'];
        }
        else if(obj.hasOwnProperty('desktop') && obj.hasOwnProperty('mobile')){
            if(!propsToAvoid.includes('desktop-mobile')){
                obj = obj[!mobileCheck ? 'desktop' : 'mobile'];
            }
        }
        else if(containsAnyUIIDKeys.call(this, obj)){
            let key = getKey(this.UIID, Enum.UIIDs);
            if(avoidFirstIfUndefined) obj = obj[key];
            else obj = firstIfUndefined(obj, key);
        }
        else if(containsAnySiteIDKeys.call(this, obj)){
            let key = getKey(this.siteId, Enum.SiteIDs);
            if(avoidFirstIfUndefined) obj = obj[key];
            else obj = firstIfUndefined(obj, key);
        }
    }
    return obj;
}

function containsAnyUIIDKeys(obj){
    if(!isObject(obj)) return false;
    return Boolean(this.UIID_KEYS.find(key => obj.hasOwnProperty(key)));
}

function containsAnySiteIDKeys(obj){
    if(!isObject(obj)) return false;
    return Boolean(this.SITE_ID_KEYS.find(key => obj.hasOwnProperty(key)));
}

function isObjAppropriate(obj, propsToAvoid){
    propsToAvoid = toArray(propsToAvoid);
    return (
        isObject(obj) && 
        (
            (obj.hasOwnProperty('prod') && obj.hasOwnProperty('test')) || 
            (!propsToAvoid.includes('desktop-mobile') ? (obj.hasOwnProperty('desktop') && obj.hasOwnProperty('mobile')) : false) || 
            containsAnyUIIDKeys.call(this, obj) || containsAnySiteIDKeys.call(this, obj) || 
            (obj.hasOwnProperty('ios') && obj.hasOwnProperty('pc')) || 
            (obj.hasOwnProperty('app') && obj.hasOwnProperty('web')) 
        )
    );
}